import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const FooterText = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [version, setVersion] = useState('');

    fetch('/.well-known/nmw-metadata').then(r => r.json()).then((metadata) => {
        setVersion('v' + metadata.majorVersion + '.' + metadata.minorVersion)
    }).catch(() => {
        setVersion('v0.0')
    })

    return (
        <div className={`visual-footer ${props.style}`}>
            <p>
                © {new Date().getFullYear()} {" "}{t("Footer.brand")}{" "}{version}{" "}|{" "}{t("Footer.rights")}{" "}|
                <span
                    className="clickable"
                    onClick={() => {
                        navigate("/termsOfService");
                    }}
                >
                    {" "}{t("Footer.terms-of-use")}{" "}
                </span>
                |
                <span
                    className="clickable"
                    onClick={() => {
                        navigate("/privacyPolicy");
                    }}
                > {" "}{t("Footer.privacy-policy")}</span>
            </p>
        </div>
    );
};

export default FooterText;
