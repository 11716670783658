import React, { useState, useEffect, useCallback } from "react";
import LeftPanel from "../LeftPanel/LeftPanel";
import config from "../../config";
import { ToastContainer, toast } from "react-toastify";
import {
  useSearchParams,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import FooterText from "../Footer/Footer";
import Cookies from "js-cookie";
import grey from "../../img/card_no-icon_grey.svg";
import { useTranslation } from "react-i18next";

import demo1 from "../../img/DEMO1.png";
import demo2 from "../../img/DEMO2.png";
import tick from "../../img/tick.png";
import alert from "../../img/alert_2.png";
import cardMC from "../../img/card.png";
import Popup from "./Popup";

export default function Wallet(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [credentials, setCredentials] = useState([]);
  const [activeCredential, setActiveCredential] = useState("");
  const [veririferInfo, setVerifierInfo] = useState({});
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [share, setShare] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupMode, setPopupMode] = useState("");
  const [popupValid, setPopupValid] = useState(true);
  const [popupAcknowledge, setPopupAcknowledge] = useState(false);

  // Check if user is logged in and handle redirection
  let cookie = Cookies.get(config.cookieName);
  if (!cookie) {
    window.location.href =
      "/sign-in?redirect_uri=" +
      encodeURIComponent(
        window.location.origin +
          "/authorize?" +
          encodeURIComponent(searchParams.toString())
      );
  }

  // Get credentials that match verifier request
  useEffect(() => {
    fetch(config.getCredentialVerifier + window.location.search, {
      method: "GET",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        // check for error response
        if (!response.ok) {
          const error = await response.text();
          return Promise.reject(error);
        } else {
          let data = await response.json();
          console.log(data);
          setCredentials(data.credentials);
          setVerifierInfo(data);

          if (!data.status) {
            setPopupValid(false);
          }
          setLoading(false);

          // Auto select if only one is available
          if (data.credentials && data.credentials.length === 1) {
            cardClickHandler(data.credentials[0].name);
          } else if (
            veririferInfo.type == "PaymentCardCredential" &&
            data.credentials &&
            data.credentials.length > 1
          ) {
            // If mode is PaymentCardCredential select first card!
            cardClickHandler(data.credentials[0].name);
          }
        }
      })
      .catch((error) => {
        console.error("Error in API Issue: ", error);
        setErrorMsg(error);
      });
  }, []);

  function cardClickHandler(id) {
    setActiveCredential(id);
  }

  function getCredential(type) {
    console.log("navigation to type");
    navigate("/explorer?type=" + type);
  }

  function shareCredential(pValid, pAck) {
    if (!pValid) {
      pValid = popupValid;
    }
    if (!pAck) {
      pAck = popupAcknowledge;
    }
    if (activeCredential !== undefined) {
      /* CHECK IF VERIFIER IS VALID  */
      if (
        (veririferInfo.type === "PaymentCardCredential" ||
          veririferInfo.type === "TravelAuthorizationCredential") &&
        pValid == false &&
        pAck == false
      ) {
        if (veririferInfo.type === "PaymentCardCredential") {
          setPopupMode("payment");
        } else {
          setPopupMode("travelAuth");
        }

        setIsPopupOpen(true);
      } else {
        let url =
          config.getSelectedShareVerifier +
          "?credential_id=" +
          activeCredential +
          "&state=" +
          veririferInfo.state;
        fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            data = JSON.parse(data);
            //console.log("redirect_uri from verifierCredentialShare: ", data.redirect_uri)
            if (data.redirect_uri && data.redirect_uri !== "") {
              if (
                veririferInfo.type === "eIDCredential" ||
                veririferInfo.type === "TravelAuthorizationCredential" ||
                veririferInfo.type === "PaymentCardCredential"
              ) {
                //setShare(true);
                if (veririferInfo.type === "PaymentCardCredential") {
                  setPopupMode("payment");
                }
                setIsPopupOpen(true);

                setTimeout(() => {
                  window.location.href = data.redirect_uri;
                }, 2000);
              } else {
                window.location.href = data.redirect_uri;
              }
            } else {
              toast.success(t("VerifierCredentialShare.toast-share-success"));
              setShare(true);
            }
          })
          .catch((error) => {
            toast.error("Error " + error);
            console.log(error);
          });
      }
    } else {
      toast.error(t("VerifierCredentialShare.toast-share-error"));
    }
  }

  const cancelshareCredential = () => {
    let r = searchParams.get("redirect_to");
    if (r) {
      window.location.href = decodeURIComponent(r);
    }
  };
  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const shareNotVerified = () => {
    setIsPopupOpen(false);
    setPopupAcknowledge(true);
    setPopupValid(true);

    shareCredential(true, true);
  };

  return (
    <div className="logged-out page-signin">
      <ToastContainer />
      {isPopupOpen && (
        <Popup
          onClose={closePopup}
          shareNotVerified={shareNotVerified}
          popupMode={popupMode}
          popupValid={popupValid}
        />
      )}
      <section className="content-wrap light-bg">
        <LeftPanel heading={t("VerifierCredentialShare.left-page")} />
        <div className="col-content w-60">
          {errorMsg !== "" && (
            <>
              <div className="messages">
                <h2>Error occurred while processing request</h2>
                <br />
                <p style={{ width: "100%", color: "#9b9b9b" }}>{errorMsg}</p>
              </div>
            </>
          )}

          {veririferInfo.type === "eIDCredential" ||
          veririferInfo.type === "TravelAuthorizationCredential" ||
          veririferInfo.type === "PaymentCardCredential" ? (
            <>
              {loading ? (
                <></>
              ) : credentials.length === 0 ? (
                <div className="messages">
                  <h2>{t("VerifierCredentialShare.title")} </h2>
                  <div className="container">
                    <p className="light">
                      {t("VerifierCredentialShare.no-credentials")
                        .replace("{{credential}}", veririferInfo.type)
                        .replace(/([A-Z])/g, " $1")
                        .trim()}
                    </p>
                    <button
                      className="btn btn-primary"
                      onClick={() =>
                        /* window.location.href = config.eidDemo*/ getCredential(
                          veririferInfo.type
                        )
                      }
                    >
                      {t("VerifierCredentialShare.no-credentials-btn")
                        .replace("{{credential}}", veririferInfo.type)
                        .replace(/([A-Z])/g, " $1")
                        .trim()}
                    </button>
                  </div>
                </div>
              ) : share ? (
                <>
                  <div className="messages m-hide">
                    <h2>{t("VerifierCredentialShare.title")}</h2>
                  </div>
                  <div className="messages">
                    <h3>SUCCESS!</h3>
                    {veririferInfo.type === "eIDCredential" ? (
                      <>
                        <p id="e-id-description">
                          Your eID credential has been successfully shared with
                          U.S. Customs and Border Protection.
                        </p>
                        <p className="e-id-subtext">
                          You will be automatically redirected to the U.S.
                          Customs and Border Protection website.
                        </p>
                      </>
                    ) : (
                      <></>
                    )}
                    {veririferInfo.type === "TravelAuthorizationCredential" ? (
                      <>
                        <p id="e-id-description">
                          Your Travel Authorization credential has been successfully shared with
                          inspector of U.S. Customs and Border Protection.
                        </p>
                      </>
                    ) : (
                      <></>
                    )}
                    
                  </div>
                </>
              ) : (
                <>
                  <div className="messages m-hide">
                    <h2>{t("VerifierCredentialShare.title")}</h2>
                  </div>

                  {veririferInfo.type === "eIDCredential" ||
                  veririferInfo.type === "TravelAuthorizationCredential" ? (
                    <div className="messages hsl_demo_messages">
                      {veririferInfo.type === "TravelAuthorizationCredential" &&
                      !veririferInfo.status ? (
                        <>
                          <div className="verifier_card">
                            <div style={{ padding: "0 5px", width: "140px" }}>
                              <h4>Unverified verifier</h4>
                            </div>
                            <div
                              style={{
                                padding: "0 5px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img className="verifier_card_icon" src={alert} />
                            </div>
                          </div>
                          <p>is requesting to share </p>
                        </>
                      ) : (
                        <>
                          <div className="verifier_card">
                            <div style={{ padding: "0 5px" }}>
                              {" "}
                              <img
                                className="verifier_card_image"
                                src={veririferInfo.logo}
                              />
                            </div>
                            <div style={{ padding: "0 5px", width: "140px" }}>
                              <h4>{veririferInfo.verifierName}</h4>
                              <p>{veririferInfo.framework}</p>
                              <p>Valid until: {veririferInfo.validUntil}</p>
                            </div>
                            <div
                              style={{
                                padding: "0 5px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                className="verifier_card_icon"
                                src={
                                  veririferInfo.validUntil != undefined
                                    ? tick
                                    : alert
                                }
                              />
                            </div>
                          </div>
                          <p>is authorised to request </p>
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="messages hsl_demo_messages">
                      <div
                        className="verifier_card"
                        style={{ height: "140px" }}
                      >
                        <div style={{ padding: "0 5px" }}>
                          {" "}
                          {veririferInfo.status ? (
                            <img
                              className="verifier_card_image"
                              src={veririferInfo.logo}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        <div style={{ padding: "0 5px", width: "140px" }}>
                          {veririferInfo.status ? (
                            <>
                              <h4>{veririferInfo.verifierName}</h4>
                              <ul className="verifier-text-ul">
                                <li>Verified identity</li>
                                <li>Verified e-Commerce</li>
                                <li>Authorised re-seller</li>
                              </ul>
                              <p>{veririferInfo.framework}</p>
                            </>
                          ) : (
                            <>
                              <h4>Unverified Shop</h4>
                              <ul className="verifier-text-ul">
                                <li>Unverified identity</li>
                                <li>Unverified e-Commerce</li>
                                <li>Unauthorised re-seller </li>
                              </ul>
                              <p>MasterCard trust framework</p>
                            </>
                          )}
                        </div>
                        <div
                          style={{
                            padding: "0 5px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <img
                            className="verifier_card_icon"
                            src={
                              veririferInfo.validUntil != undefined &&
                              veririferInfo.status
                                ? tick
                                : alert
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="container">
                    <div className="credentials-container">
                      {veririferInfo.type != "PaymentCardCredential" ? (
                        <>
                          {credentials.map((credential, index) => (
                            <div className="explorer-card-wrap" key={index}>
                              <div
                                className="explorer-card"
                                style={{
                                  backgroundImage: `url("${
                                    activeCredential == credential.name ||
                                    activeCredential == ""
                                      ? "html/img/card_no-icon_" +
                                        credential.bg_color +
                                        ".svg"
                                      : grey
                                  }")`, //credential.bg_color
                                }}
                                onClick={() =>
                                  cardClickHandler(credential.name)
                                }
                              >
                                <div className="container">
                                  <p className="item subtitle">
                                    {credential.credential_title}
                                  </p>
                                  <p className="item title">
                                    {credential.credential_title ===
                                    "MyNextID Email Credential (Self)" ? (
                                      <strong>{props.userEmail}</strong>
                                    ) : (
                                      <strong>
                                        {truncateString(
                                          credential.credential_identifier,
                                          40
                                        )}
                                      </strong>
                                    )}
                                  </p>
                                  <div className="item">
                                    <p>
                                      {t("VerifierCredentialShare.form-text-1")}{" "}
                                      {truncateString(
                                        credential.issuer_title,
                                        20
                                      )}
                                    </p>
                                    {/* <p className="item">Issuer: {credential.issuer_title}</p> */}

                                    {credential.valid_from && (
                                      <p>
                                        {t(
                                          "VerifierCredentialShare.form-text-2"
                                        )}{" "}
                                        {credential.valid_from.split("T")[0]}
                                      </p>
                                    )}
                                    {!credential.valid_from &&
                                      credential.expires_in && (
                                        <p>
                                          {t(
                                            "VerifierCredentialShare.form-text-3"
                                          )}{" "}
                                          {credential.expires_in.split("T")[0]}
                                        </p>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        <div
                          style={{ paddingTop: "10px" }}
                          className="messages hsl_demo_messages"
                        >
                          <div className="verifier_card">
                            <div
                              style={{ margin: "auto", textAlign: "center" }}
                            >
                              <h4>Payment summary</h4>
                              <p>
                                Shoe shop | Converse |{" "}
                                {veririferInfo.extraInformation.price} EUR
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {veririferInfo.type === "eIDCredential" ? (
                      <>
                        <p className="p_hls_demo">
                          for the purpose of issuing Travel Authorization
                          Documents. Verifiable Credential(s) are used for the
                          purpose of
                        </p>
                        <li className="p_hls_demo">identification</li>

                        <p className="p_hls_demo">
                          Information retention period: 1 year.
                        </p>

                        <button
                          className="btn btn-primary"
                          onClick={() => shareCredential()}
                        >
                          SHARE
                        </button>
                        <button
                          className="btn btn-secondary"
                          onClick={() => cancelshareCredential()}
                        >
                          CANCEL
                        </button>
                      </>
                    ) : veririferInfo.type ===
                      "TravelAuthorizationCredential" ? (
                      <>
                        <p className="p_hls_demo">
                          for the purpose of entering the US. Verifiable
                          Credential(s) are used for the purpose of
                        </p>
                        <li className="p_hls_demo">identification</li>
                        <li className="p_hls_demo">entry authorization</li>

                        <p className="p_hls_demo">
                          Information retention period: 1 year.
                        </p>

                        <button
                          className="btn btn-primary"
                          onClick={() => shareCredential()}
                        >
                          SHARE
                        </button>
                        <button
                          className="btn btn-secondary"
                          onClick={() => cancelshareCredential()}
                        >
                          CANCEL
                        </button>
                      </>
                    ) : (
                      //veririferInfo.type == PaymentCardCredential
                      <>
                        <p
                          style={{ paddingTop: "20px" }}
                          className="p_hls_demo"
                        >
                          Pay with Verifiable MasterCard
                        </p>

                        <img className="img_webshop_demo" src={cardMC}></img>

                        <button
                          id="btn-demo-1"
                          className="btn btn-primary btn_webshop_demo"
                          onClick={() => shareCredential()}
                        >
                          CONFIRM PAYMENT
                        </button>
                        <button
                          id="btn-demo-2"
                          className="btn btn-secondary btn_webshop_demo"
                          onClick={() => cancelshareCredential()}
                        >
                          CANCEL
                        </button>
                      </>
                    )}
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {loading ? (
                <></>
              ) : credentials.length === 0 ? (
                <div className="messages">
                  <h2>{t("VerifierCredentialShare.title")} </h2>
                  <div className="container">
                    <p className="light">
                      {t("VerifierCredentialShare.no-credentials")
                        .replace("{{credential}}", veririferInfo.type)
                        .replace(/([A-Z])/g, " $1")
                        .trim()}
                    </p>
                    <button
                      className="btn btn-primary"
                      onClick={() => getCredential(veririferInfo.type)}
                    >
                      {t("VerifierCredentialShare.no-credentials-btn")
                        .replace("{{credential}}", veririferInfo.type)
                        .replace(/([A-Z])/g, " $1")
                        .trim()}
                    </button>
                  </div>
                </div>
              ) : share ? (
                <>
                  <div className="messages m-hide">
                    <h2>{t("VerifierCredentialShare.title")}</h2>
                  </div>
                  <div className="messages">
                    <p>
                      {veririferInfo.type}{" "}
                      {t("VerifierCredentialShare.shared-credential")}{" "}
                      <b>
                        {t("VerifierCredentialShare.msg-1-bold").replace(
                          "{{verifier}}",
                          veririferInfo.verifier
                        )}
                      </b>
                      .{" "}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div className="messages m-hide">
                    <h2>{t("VerifierCredentialShare.title")}</h2>
                  </div>
                  <div className="messages">
                    <p>
                      <b>
                        {t("VerifierCredentialShare.msg-1-bold").replace(
                          "{{verifier}}",
                          veririferInfo.verifier
                        )}
                      </b>{" "}
                      {t("VerifierCredentialShare.msg-2").replace(
                        "{{type}}",
                        veririferInfo.type
                      )}{" "}
                    </p>
                    {credentials.length === 1 ? (
                      ""
                    ) : (
                      <p>{t("VerifierCredentialShare.msg-3")}</p>
                    )}
                  </div>
                  <div className="container">
                    <div className="credentials-container">
                      {credentials.map((credential, index) => (
                        <div className="explorer-card-wrap" key={index}>
                          <div
                            className="explorer-card"
                            style={{
                              backgroundImage: `url("${
                                activeCredential == credential.name ||
                                activeCredential == ""
                                  ? "html/img/card_no-icon_" +
                                    credential.bg_color +
                                    ".svg"
                                  : grey
                              }")`, //credential.bg_color
                            }}
                            onClick={() => cardClickHandler(credential.name)}
                          >
                            <div className="container">
                              <p className="item subtitle">
                                {credential.credential_title}
                              </p>
                              <p className="item title">
                                {credential.credential_title ===
                                "MyNextID Email Credential (Self)" ? (
                                  <strong>{props.userEmail}</strong>
                                ) : (
                                  <strong>
                                    {truncateString(
                                      credential.credential_identifier,
                                      40
                                    )}
                                  </strong>
                                )}
                              </p>
                              <div className="item">
                                <p>
                                  {t("VerifierCredentialShare.form-text-1")}{" "}
                                  {truncateString(credential.issuer_title, 20)}
                                </p>
                                {/* <p className="item">Issuer: {credential.issuer_title}</p> */}

                                {credential.valid_from && (
                                  <p>
                                    {t("VerifierCredentialShare.form-text-2")}{" "}
                                    {credential.valid_from.split("T")[0]}
                                  </p>
                                )}
                                {!credential.valid_from &&
                                  credential.expires_in && (
                                    <p>
                                      {t("VerifierCredentialShare.form-text-3")}{" "}
                                      {credential.expires_in.split("T")[0]}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <button
                      className="btn btn-primary"
                      onClick={() => shareCredential()}
                    >
                      {t("VerifierCredentialShare.share-btn")}
                    </button>
                    <p style={{ fontSize: "small", textAlign: "justify" }}>
                      {t("VerifierCredentialShare.msg-4").replace(
                        "{{verifier}}",
                        veririferInfo.verifier
                      )}
                    </p>

                    {veririferInfo.issuer && (
                      <div>
                        <p >
                            {veririferInfo.status  && 
                                <b > {t("VerifierCredentialShare.msg-5").replace( "{{issuer}}", veririferInfo.issuer )} </b>
                            }

                            {veririferInfo.statusReason == "Authorized Verifier Credential is revoked" && 
                            <>
                                 <img style={{width:"20px"}} src={alert} />
                                <b >  {t("VerifierCredentialShare.msg-6").replace( "{{issuer}}", veririferInfo.issuer )} </b>
                            </>
                                
                            }
                         
                        </p>
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <FooterText style="m-show" />
      </section>
    </div>
  );
}

function truncateString(id, maxLength) {
  if (id.length > maxLength) {
    const start = id.substr(0, maxLength - 3);
    return `${start}...`;
  }
  return id;
}
