export const CREATE_ISSUER = "CREATE_ISSUER";
export const RETRIEVE_ISSUERS = "RETRIEVE_ISSUERS";
export const RETRIEVE_ISSUERS_FOR_SCHEMA = "RETRIEVE_ISSUERS_FOR_SCHEMA";
export const UPDATE_ISSUER = "UPDATE_ISSUER";
export const DELETE_ISSUER = "DELETE_ISSUER";
export const DELETE_ALL_ISSUERS = "DELETE_ALL_ISSUERS";
export const CREATE_VC = "CREATE_VC";
export const RETRIEVE_VCS = "RETRIEVE_VCS";
export const RETRIEVE_VCS_FOR_ISSUER = "RETRIEVE_VCS_FOR_ISSUER";
export const UPDATE_VC = "UPDATE_VC";
export const DELETE_VC = "DELETE_VC";
export const DELETE_ALL_VCS = "DELETE_ALL_VCS";
export const GET_AUTHORIZE = "GET_AUTHORIZE";
export const POST_TOKEN = "POST_TOKEN";
export const POST_CREDENTIAL = "POST_CREDENTIAL";
export const RETRIEVE_SCHEMAS_FOR_ISSUER = "RETRIEVE_SCHEMAS_FOR_ISSUER";
export const RETRIEVE_EBSI_SCHEMAS = "RETRIEVE_EBSI_SCHEMAS";
export const RETRIEVE_CREDENTIALS = "RETRIEVE_CREDENTIALS";
export const RETRIEVE_CREDENTIAL = "RETRIEVE_CREDENTIAL";
export const DELETE_CREDENTIAL = "DELETE_CREDENTIAL";
export const RESET_STATE = "RESET_STATE";
export const RETRIEVE_SCHEMAS = "RETRIEVE_SCHEMAS";
export const RETRIEVE_BRANDS = "RETRIEVE_BRANDS";
export const RETRIEVE_BRAND = "RETRIEVE_BRAND";
export const RETRIEVE_SCHEMAS_FOR_BRAND = "RETRIEVE_SCHEMAS_FOR_BRAND";
export const RETRIEVE_FACTORIES = "RETRIEVE_FACTORIES";