import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "../../custom.css";
import CredentialCard from "../CredentialExplorer/credentialCard";
import GoogleAnalytics from "../GA";
import { useTranslation } from 'react-i18next';

function IssuanceNotification(props) {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const [message, setMessage] = useState("Loading...");

    let init_request = props.init_request;
    init_request = JSON.parse(init_request.credential_offer);   

    const [searchParams] = useSearchParams();

    // On user consent with issuance
    function clickAcceptHandler() {

        // GA4 Creation of new credential approved
        GoogleAnalytics('created_new_credential', 'User');

        let linkDestination =
            window.location.origin +
            "/api/v1/initiate_issuance?" +
            searchParams.toString();
        try {
            window.location.href = linkDestination;
        } catch (error) {
            console.log(error);
        }
    }

    function clickRejectHandler() {
        // GA4 Creation of new credential approved
        GoogleAnalytics('rejected_creation_of_new_credential', 'User');

        props.onReject();
    }

    return (
        <div className="container">
            {isLoading && (
                <div className="messages">
                    <h2>{message}</h2>
                </div>
            )}
            {!isLoading && (
                <div className="messages">
                    <p className="light">{t("IssuanceNotification.title")}</p>
                    <br />
                    <CredentialCard
                        name={init_request.credential_name}
                        issuer={init_request.issuer_name}
                        image="html/img/card_no-icon_purple.svg"
                        identifier={init_request.identifier}
                    />

                    <p>{t("IssuanceNotification.subtitle")}{" "}{init_request.issuer_name}</p>

                    <button
                        className="btn btn-primary"
                        style={{ width: "190px", marginRight: "20px" }}
                        onClick={clickAcceptHandler}
                    >{t("IssuanceNotification.accept-btn")}</button>
                    <button
                        className="btn btn-secondary"
                        style={{ width: "190px" }}
                        onClick={clickRejectHandler}
                    >{t("IssuanceNotification.reject-btn")}</button>
                </div>
            )}
        </div>
    );
}

export default IssuanceNotification;
