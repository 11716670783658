import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import UTC from "./utc";
import { fontWeight } from "@mui/system";
import { useTranslation } from "react-i18next";
import config from "../../config";
import tick from "../../img/tick.png";
import alert from "../../img/alert_2.png";
import cross from "../../img/cross.png";

function DataList(props) {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [credentialSubject, setCredentialSubject] = useState("");
    const [issuanceDate, setIssuanceDate] = useState("");
    const [image, setImage] = useState("");
    const [verifications, setVerifications] = useState([]);

    useEffect(() => {
        // EuropeanDigitalCredential flow @Jan Gerl 04/06/2024
        if (props.details.data.credential.credential[0] === "{") {
            let credential = JSON.parse(
                props.details.data.credential.credential
            );
            setData(credential);
            setCredentialSubject(formatKeys(credential.credentialSubject));
            setIssuanceDate(credential.validFrom);
            setImage(credential.image);

            verifyCredential(props.details.data.credential_raw);
            /* .then((data) => console.log(data))
                .catch((error) => console.log(error)); */
        }
        // Normal MyNextID flow
        else {
            props.details.data.credential.credential = jwt_decode(
                props.details.data.credential.credential
            );
            setData(props.details.data.credential.credential.vc);
            setCredentialSubject(
                formatKeys(
                    props.details.data.credential.credential.vc
                        .credentialSubject
                )
            );
            setIssuanceDate(
                props.details.data.credential.credential.vc.issuanceDate
            );
        }
    }, []);

    const verifyCredential = (credential) => {
        fetch(config.verifyCredentialUrl, {
            method: "POST",
            body: JSON.stringify({
                credential: credential,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                //console.log(data);
                setVerifications(data);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const formatKeys = (data) => {
        let formattedData = JSON.stringify(data);
        formattedData = Array.isArray(data) ? [] : {};

        Object.keys(data).forEach((key) => {
            let formattedKey;
            switch (key) {
                case "eid":
                    formattedKey = "eID";
                case "end_date":
                    formattedKey = "Valid until";
                case "start_date":
                    formattedKey = "Valid from";
                default:
                    formattedKey =
                        key.charAt(0).toUpperCase() +
                        key.slice(1).replace(/_/g, " ");
            }

            if (typeof data[key] === "object" && data[key] !== null) {
                formattedData[formattedKey] = formatKeys(data[key]);
            } else {
                formattedData[formattedKey] = data[key];
            }
        });
        return formattedData;
    };

    function truncateString(id) {
        const maxLength = 20;
        if (id.length > maxLength) {
            const start = id.substr(0, maxLength / 2 - 1);
            const end = id.substr(id.length - maxLength / 2 + 1);
            return `${start}...${end}`;
        }
        return id;
    }

    function isArrayOfStrings(arr) {
        return (
            Array.isArray(arr) && arr.every((item) => typeof item === "string")
        );
    }

    const renderTableRows = (data, level) => {
        return Object.entries(data).map(([key, value]) => {
            if (typeof value === "object") {
                if (Array.isArray(value)) {
                    if (isArrayOfStrings(value)) {
                        return (
                            <React.Fragment>
                                <tr
                                    key={key}
                                    style={{
                                        width: "100%",
                                        display: "inline-block",
                                    }}
                                >
                                    <td
                                        className="col-1"
                                        style={{
                                            fontWeight: 400,
                                            paddingLeft: "30px",
                                        }}
                                    >
                                        {key}
                                    </td>
                                    <td className="col-2"></td>
                                </tr>
                                {value.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <tr key={index}>
                                                <td className="col-1"></td>
                                                <td className="col-2">
                                                    {item}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    );
                                })}
                            </React.Fragment>
                        );
                    } else {
                        const formattedKey =
                            key.charAt(0).toUpperCase() +
                            key.slice(1).replace(/_/g, " ");
                        return (
                            <React.Fragment>
                                <tr
                                    key={key}
                                    style={{
                                        width: "100%",
                                        display: "inline-block",
                                    }}
                                >
                                    <td className="col-1">{formattedKey}</td>
                                    <td className="col-2"></td>
                                </tr>
                                {value.map((item, index) => (
                                    <React.Fragment key={index}>
                                        {renderTableRows(item, 1)}
                                    </React.Fragment>
                                ))}
                            </React.Fragment>
                        );
                    }
                } else {
                    const formattedKey =
                        key.charAt(0).toUpperCase() +
                        key.slice(1).replace(/_/g, " ");
                    return (
                        <React.Fragment>
                            <tr key={key} style={{ width: "100%" }}>
                                <td className="col-1">{formattedKey}</td>
                                <td className="col-2"></td>
                            </tr>
                            {renderTableRows(value, 1)}
                        </React.Fragment>
                    );
                }
            } else {
                let levelPadding = 10 + level * 20;
                let fontWeight;
                if (levelPadding > 10) fontWeight = 400;

                const formattedKey =
                    key.charAt(0).toUpperCase() +
                    key.slice(1).replace(/_/g, " ");

                let formattedValue = <p
                    style={{
                        margin: "0",
                        whiteSpace: "initial",
                        overflowWrap: "break-word",
                        width: "100%",
                    }}
                >
                    {value ? value : "/"}
                </p>;
                // For AgraCredential, make sure the Award is shown as the image
                if(formattedKey === "Award" && value.startsWith("https://")) {
                    formattedValue = <img style={{margin: "0", width: "100%"}} src={value} alt={value}/>
                }
                return (
                    <tr key={key} style={{ width: "100%" }}>
                        <td
                            className="col-1"
                            style={{
                                fontWeight: fontWeight,
                                paddingLeft: levelPadding + "px",
                            }}
                        >
                            {formattedKey}
                        </td>
                        <td style={{ maxWidth: "100%" }} className="col-2">
                            {formattedValue}
                        </td>
                    </tr>
                );
            }
        });
    };

    return (
        <div className="datalist-container">
            <div className="messages">
                <h2>{t("DataList.title")}</h2>
            </div>
            <table className="table">
                <tbody>
                    <tr key="title">
                        <td className="col-1" key="key1">
                            {t("DataList.form-text-1")}
                        </td>
                        <td
                            className="col-2"
                            style={{
                                overflowWrap: "break-word",
                                whiteSpace: "initial",
                            }}
                            key="key2"
                        >
                            {props.credentialData.credential_title}
                        </td>
                    </tr>
                    {/*  {Object.entries(credentialSubject).map(([key, value]) => (
                        <tr key={key}>
                        <td className="col-1">{key}</td>
                        <td className="col-2">{value}</td>
                        </tr>
                    ))} */}
                    {renderTableRows(credentialSubject)}
                    <tr key="issuer">
                        <td className="col-1" key="key3">
                            {t("DataList.form-text-2")}
                        </td>
                        <td
                            className="col-2"
                            style={{
                                overflowWrap: "break-word",
                                whiteSpace: "initial",
                            }}
                            key="key4"
                        >
                            {props.credentialData.issuer_title}{" "}
                        </td>
                    </tr>
                    <tr key="validFrom">
                        <td className="col-1">{t("DataList.form-text-3")}</td>
                        <td
                            className="col-2"
                            style={{
                                overflowWrap: "break-word",
                                whiteSpace: "initial",
                            }}
                        >
                            <UTC utc={issuanceDate} />
                        </td>
                    </tr>
                    {/*  <tr>
                        <td className="col-1">Scope</td>
                        <td className="col-2">{props.credentialData.credential_type} </td>
                    </tr> */}

                    {/* {data.id && (
                        <tr>
                        <td className="col-1">Id</td>
                        <td className="col-2">{truncateId(data.id)}</td>
                        </tr>
                    )} */}
                    {/* <tr>
                        <td>Type</td>
                        {data.type.map((type) => (
                        <td>{type}</td>
                        ))}
                    </tr> */}
                </tbody>
            </table>
            {verifications.length > 0 && (
                <div className="verification-container">
                    <h2>Verifications</h2>
                    <table className="table">
                        <tbody>
                            {verifications.map((verification, index) => (
                                <tr key={index}>
                                    <td
                                        className="col-1"
                                        style={{
                                            width: "unset",
                                            padding: "6px 12px",
                                        }}
                                    >
                                        {verification.status.prefLabel.en[0] ===
                                        "green" ? (
                                            <img
                                                src={tick}
                                                alt="tick"
                                                style={{
                                                    height: "auto",
                                                    width: "24px",
                                                }}
                                            />
                                        ) : verification.status.prefLabel
                                              .en[0] === "red" ? (
                                            <img
                                                src={cross}
                                                alt="cross"
                                                style={{
                                                    height: "auto",
                                                    width: "24px",
                                                }}
                                            />
                                        ) : (
                                            <img
                                                src={alert}
                                                alt="alert"
                                                style={{
                                                    height: "auto",
                                                    width: "24px",
                                                }}
                                            />
                                        )}
                                    </td>
                                    <td
                                        className="col-2"
                                        style={{
                                            overflowWrap: "break-word",
                                            whiteSpace: "initial",
                                        }}
                                    >
                                        {verification.description.en}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            {image && (
                <div className="image-container">
                    <img src={`data:image/jpeg;base64,${image}`} alt="image" />
                </div>
            )}
        </div>
    );
}

export default DataList;
