import React from "react";
import { useTranslation } from 'react-i18next';

export default function DetailsCredential(props) {
    const { t } = useTranslation();
    let credential = props.credential;

    return (
        <div className="details-card" style={{ backgroundImage: `url("html/img/card_no-icon_${credential.bg_color}.svg")` }}>
            <div className="container">
                <p className="item subtitle">{credential.credential_title}</p>
                <p className="item title">
                    {credential.credential_title ===
                        "MyNextID Email Credential (Self)" ? (
                        <strong>{props.userEmail}</strong>
                    ) : (
                        <strong>{credential.credential_identifier}</strong>
                    )}
                </p>
                <div className="item">
                    <p>{t("detailsCredential.form-text-1")}{" "}{credential.issuer_title}</p>
                    {/* <p className="item">Issuer: {credential.issuer_title}</p> */}

                    {/* {credential.expires_in ? (
                <p>Expires: {credential.expires_in.split(" ")[0]}</p>
              ) : (
                credential.issued_at && (
                <p>Valid from: {credential.issued_at.split(" ")[0]}</p>
                )
              )} */}
                    {credential.valid_from && <p>{t("detailsCredential.form-text-2")}{" "}{credential.valid_from.split("T")[0]}</p>}
                    {!credential.valid_from && <p>{t("detailsCredential.form-text-3")}{" "}{credential.expires_in.split("T")[0]}</p>}
                </div>
            </div>
        </div>
    );
}
