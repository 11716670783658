
import React, { useState, useEffect, useCallback } from "react";
import { useSearchParams, Link, useNavigate, useLocation} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import LeftPanel from "../LeftPanel/LeftPanel";
import { Autocomplete, TextField } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import Cookies from "js-cookie";

import FooterText from "../Footer/Footer";
import config from "../../config";

import tick from "../../img/tick.png";
import alert from "../../img/alert_2.png";

import StepNavigation from "./StepNavigation";

const InspectorApp = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [multipleTypes, setMultipleTypes] = useState(true);
    const [selected, setSelected] = useState("");
   
    const [verifierTypes, setVerifierTypes] = useState([]);
    const [searchParams] = useSearchParams();
    const [VCcode, setVCcode] = useState("");

    let cookie = Cookies.get(config.cookieName);
    console.log(cookie)
    if (!cookie) {
        window.location.href = "/sign-in";
    }

    useEffect(() => {
        fetch(config.getAuthorizedVerifierCred)
            .then(async (response) => {
                // check for error response
                if (!response.ok) {
                    const error = await response.text();
                    return Promise.reject(error);
                } else {
                    let data = await response.json();
                    //console.log(data);
                    if (data.length > 0) {
                        setVerifierTypes(data);
                    }
                }
            })
            .catch((error) => {
                console.error("Error in API Issue: ", error);
            });
    }, []);


    const [qrCode, setQrCode] = useState("");
    const [buttonLink, setButtonLink] = useState("");
    const [show, setShow] = useState(false);
    const [scenario, setScenario] = useState("");
    const [output, setOutput] = useState();
    const [resultVerifier, setResultVerifier] = useState({});
    const [sessionId, setSessionId] = useState("");

    const handleChange = (event, value) => {
        if (value !== null) {
            setSelected(value);
            console.log(value);
        } else {
            setSelected("");
        }
    };
    const startFlow = (event, value) => {
        setScenario(selected);
        setQrCode("");
    };

    useEffect(() => {
        if (scenario !== "") {
            fetch(config.authRequestApi + "?type=" + scenario)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    setQrCode(data.url);
                    console.log(data.url);
                    setButtonLink(`${data.url}&session_id=${data.sessionId}`);
                    //setButtonLink(`${data.sameDevice}&session_id=${data.sessionId}`);

                    // SSE: sessionId is saved to sessionId variable here
                    setSessionId(data.sessionId);

                    let eventsource = new EventSource(
                        config.serverUrl + `/events?id=${data.sessionId}`
                    );

                    eventsource.onmessage = function (event) {
                        // make sure it's not a ping message!
                        if (event.data !== "ping") {
                            console.log("event data: ", event.data);
                            let url = new URL(event.data);
                            eventsource.close();

                            navigate(
                                `/inspectorAppResult?type=${selected}&responseCode=${url.searchParams.get(
                                    "responseCode"
                                )}`
                            );
                        } else {
                            console.log("PING!");
                        }
                    };

                    eventsource.onerror = function (event) {
                        eventsource.close();
                    };
                });
        }
    }, [scenario]);

    return (
        <div className="logged-out page-signin">
            <ToastContainer />

            <section className="content-wrap light-bg">
                <LeftPanel heading={t("InspectorApp.left-page")} isInspector={true} />

                <div className="col-content w-60">
                <div className="container">
                    <StepNavigation step={(qrCode) ? 1: 0}/>
                        {/*<div className="messages m-hide">
                            <h2>{t("InspectorApp.title")} </h2>
                        </div>*/}

                        {scenario == "" ? <>
                            <p>Select from the dropdown below the verifiable document that you would like to verify</p>

                            {Object.keys(resultVerifier).length == 0 ? (
                                <>
                                    {multipleTypes ? (
                                        <div className="dropdown search-container search ">
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                className="autocomplete-dropdown"
                                                options={verifierTypes}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t("InspectorApp.form-label-1")}
                                                    />
                                                )}
                                                onChange={handleChange}
                                                fullWidth={true}
                                                style={{marginTop:"25px"}}
                                            />
                                            <button className="btn btn-primary" onClick={() => {startFlow()}}>{t("InspectorApp.form-button")}</button>
                                        </div>
                                        
                                    ) : (
                                        <></>
                                    )}

                                    
                                </>
                            ) : (
                                <></>
                            )}
                        </>:<>
                            <p>{t("InspectorApp.qr-text")}{scenario}</p>
                                   
                            <QRCodeSVG
                                value={qrCode}
                                height="200"
                                width="200"
                                className="qrcode_verify"
                            />

                            <p>{t("InspectorApp.qr-desc").replace("{{scenario}}",scenario)}</p>
                        </>}
                        
                    </div>
       
                    
                </div>

                <FooterText style="m-show" />
            </section>
        </div>
    )
}

export default InspectorApp
