import { RETRIEVE_CREDENTIALS, RETRIEVE_CREDENTIAL, DELETE_CREDENTIAL, RESET_STATE } from "../actions/types";

const initialState = [];

function credentialReducer(credentials = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_CREDENTIALS:
      return payload;

    case RETRIEVE_CREDENTIAL:
      return payload;

    case DELETE_CREDENTIAL:
      return payload;

    case RESET_STATE:
      return initialState;

    default:
      return credentials;
  }
}

export default credentialReducer;
