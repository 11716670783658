import React, { useState, useEffect, memo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import config from "../../config";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";

function CredentialNotification(props) {
    let navigate = useNavigate();

    useEffect(() => {
        if (props.toastMessage) {
            let action = props.toastMessage.action;
            if (action === "success")
                toast.success(props.toastMessage.message, {
                    onOpen: handleToastOpen,
                    onClose: handleToastClose,
                });
            else if (action === "error") toast.error(props.toastMessage.message);
        }
    }, [props.toastMessage]);

    const handleToastClose = () => {
        navigate("/wallet");
    };

    const handleToastOpen = () => {
        //console.log("toast opened");
    };

    return null;
}

export default CredentialNotification;
