import { RETRIEVE_CREDENTIAL } from "../actions/types";

const initialState = [];

function credentialDetailsReducer(credentialDetails = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_CREDENTIAL:
      return payload;

    default:
      return credentialDetails;
  }
}

export default credentialDetailsReducer;
