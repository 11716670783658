import { RETRIEVE_SCHEMAS_FOR_ISSUER, RETRIEVE_SCHEMAS } from "../actions/types";

const initialState = [];

function schemaReducer(schemas = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_SCHEMAS_FOR_ISSUER:
      return payload;
    case RETRIEVE_SCHEMAS:
      return payload;
    default:
      return schemas;
  }
}

export default schemaReducer;
