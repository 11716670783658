import { RETRIEVE_CREDENTIALS, RETRIEVE_CREDENTIAL, DELETE_CREDENTIAL, RESET_STATE } from "./types";
import CredentialDataService from "../services/credentials.service";

export const retrieveCredentials = () => async (dispatch) => {
  try {
    const res = await CredentialDataService.getAll();

    dispatch({
      type: RETRIEVE_CREDENTIALS,
      payload: res.data,
    });
  } catch (err) {
    console.log("Error retrieving credentials: ");
    console.log(err);
  }
};

export const retrieveCredential = (key) => async (dispatch) => {
  try {
    const res = await CredentialDataService.get({ key });

    dispatch({
      type: RETRIEVE_CREDENTIAL,
      payload: res.data,
    });

    //return Promise.resolve(res.data);
  } catch (err) {
    console.log(err);
  }
};

export const deleteCredential = (id) => async (dispatch) => {
  try {
    const res = await CredentialDataService.delete(id);

    dispatch({
      type: DELETE_CREDENTIAL,
      payload: { id },
    });
  } catch (err) {
    console.log(err);
  }
};

export const resetState = () => async (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};
