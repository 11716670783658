import React from "react";
import Cookies from "js-cookie";
import config from "../../config";
import { useSearchParams } from "react-router-dom";
import LeftPanel from "../LeftPanel/LeftPanel";
import FooterText from "../Footer/Footer";
import GoogleAnalytics from "../GA";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

function Signin(props) {
  //Check if user is logged in
  const { t } = useTranslation();
  let cookie = Cookies.get(config.cookieName);
  if (cookie) {
    console.log("Already logged in, redirecting to wallet...");
    props.onLogin();
  } else {
    console.log("Not logged in");
  }

  const [searchParams] = useSearchParams();

  function redirectToLogin(event) {
    // GA4 Btn for login
    ReactGA.event({
      action: "Login_wallet",
      category: "User",
    });

    event.preventDefault();
    let redirectUrl = config.auth0Login + "?" + searchParams.toString();
    window.location.href = redirectUrl;
  }

  return (
    <React.Fragment>
      <div className="logged-out page-signin">
        <section className="content-wrap">
          <img src="html/img/logo_mynextID.svg" alt="" className="logo" />
          <LeftPanel heading={t("Signin.left-page")} />
          <div className="col-content w-50">
            <div className="signin-container">
              <h2>{t("Signin.title")} </h2>
              <button onClick={redirectToLogin} className="btn btn-primary">
                {t("Signin.login-btn")}
              </button>
            </div>
          </div>
          <FooterText style="m-show" />
        </section>
      </div>
    </React.Fragment>
  );
}

export default Signin;
