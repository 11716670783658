import React from "react";

function UTC({ utc }) {
  const date = new Date(utc);
  const formattedDate = `${date.getUTCFullYear()}-${(
    date.getUTCMonth() + 1
  ).toString().padStart(2, "0")}-${date
    .getUTCDate()
    .toString()
    .padStart(2, "0")}`;
  const formattedTime = `${date
    .getUTCHours()
    .toString()
    .padStart(2, "0")}:${date.getUTCMinutes().toString().padStart(2, "0")}:${date
    .getUTCSeconds()
    .toString()
    .padStart(2, "0")}`;
  return (
    <div>
      {formattedDate} at {formattedTime}     
    </div>
  );
}

export default UTC;