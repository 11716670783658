import React, { useState, useEffect, useCallback } from "react";
import Credential from "../Credential/credential";
import LeftPanel from "../LeftPanel/LeftPanel";
import Cookies from "js-cookie";
import {
    useSearchParams,
    Link,
    useNavigate,
    useLocation,
} from "react-router-dom";
import config from "../../config";
import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { retrieveCredentials, resetState } from "../../actions/credentials";
import IssuanceNotification from "../Notification/issuanceNotification";
import CredentialNotification from "../Notification/credentialNotification";
import AboutPortfolio from "../Portfolio/aboutPortfolio";
import ShareCredentialNotification from "../Notification/shareCredentialNotification";
import CredentialOfferNotification from "../Notification/credentialOfferNotification";
import CredentialCard from "../CredentialExplorer/credentialCard";
import FooterText from "../Footer/Footer";

import GoogleAnalytics from "../GA";
import { useTranslation } from 'react-i18next';

export default function Wallet(props) {
    const { t } = useTranslation();
    // ToDo: Validate initiation request, forward the correct parameters to the issuer
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [publishList, setPublishList] = useState();

    // Check if user is logged in and handle redirection
    let cookie = Cookies.get(config.cookieName);
    if (!cookie) {
        if (props.action === "issuanceNotification") {
            window.location.href =
                "/sign-in?redirect_uri=" +
                window.location.origin +
                "/initiate_issuance?" +
                encodeURIComponent(searchParams.toString());
        } else if (props.action === "credentialNotification") {
            window.location.href =
                "/sign-in?redirect_uri=" +
                window.location.origin +
                "/credential?" +
                encodeURIComponent(searchParams.toString());
        } else if (props.action === "shareCredentialNotification") {
            window.location.href =
                "/sign-in?redirect_uri=" +
                window.location.origin +
                "/saveCredential?" +
                encodeURIComponent(searchParams.toString());
        } else if (props.action === "credentialOfferNotification") {
            //console.log("/sign-in?redirect_uri=" + window.location.origin + "/credential_offer?" + encodeURIComponent(searchParams.toString()));
            window.location.href =
                "/sign-in?redirect_uri=" +
                window.location.origin +
                "/credential_offer?" +
                encodeURIComponent(searchParams.toString());
        } else {
            window.location.href = "/sign-in";
        }
    }

    //------------------------ checkbox for publish / unpublish----------------------------

    useEffect(() => {
        //console.log("Onboarded: ", props.onboarded);
        if (props.onboarded) {
            checkIfCredentialIsPublic();
        }
    }, [props.onboarded]);

    const [isCredentialOfferNotification, setIsCredentialOfferNotification] =
        useState(false);
    useEffect(() => {
        if (props.action === "credentialOfferNotification")
            setIsCredentialOfferNotification(true);
    }, [props.action]);

    // Check if credential is public
    async function checkIfCredentialIsPublic() {
        fetch(config.publicEndpoint)
            .then(async (response) => {
                // check for error response
                if (!response.ok) {
                    const error = await response.text();
                    return Promise.reject(error);
                } else {
                    const data = await response.text();
                    setPublishList(data);
                }
            })
            .catch((error) => {
                console.error("Error in API Issue: ", error);
            });
    }
    //-------------------- END for checkbox for publish / unpublish----------------------------

    // Write all params to object init_request
    const init_request = {};
    searchParams.forEach((value, key) => {
        init_request[key] = value;
    });

    const credentials = useSelector((state) => state.credentials);
    //console.log("Credentials", credentials);
    const dispatch = useDispatch();

    const [closeNotification, setCloseNotification] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(retrieveCredentials()).then(() => {
            setLoading(false);
        });
        //dispatch(resetState());
    }, []);

    function onSuccessAction() {
        setCloseNotification(true);
        navigate("/");
    }

    const [toastMessage, setToastMessage] = useState({});
    const [searchCredentialParams] = useSearchParams();
    const [credentialRequest, setCredentialRequest] = useState({});
    useEffect(() => {
        if (props.action === "credentialNotification") {
            searchCredentialParams.forEach((value, key) => {
                credentialRequest[key] = value;
            });

            if (credentialRequest.wait !== undefined) {
                try {
                    setToastMessage({
                        message: t("Wallet.credentialReq"),
                        action: "success",
                    });
                } catch (error) {
                    setToastMessage({
                        message: t("Wallet.credentialReqError") + " " + error,
                        action: "error",
                    });
                }
            } else {
                // Decode and parse credential
                try {
                    let text = t("Wallet.credentialReqSuccess");
                    setToastMessage({
                        message: text,
                        action: "success",
                    });
                } catch (error) {
                    setToastMessage({
                        message: t("Wallet.credentialReqError") + " " + error,
                        action: "error",
                    });
                }
            }
        }
    }, [props.action === "credentialNotification"]);

    // Check if user deleted the credential and show a toast message
    const { state } = useLocation();
    if (state) {
        if (state.removeMessage) {
            if (state.removeMessage.action === "success") {
                toast.success(state.removeMessage.message, { onClose: navigate("/") });
            } else {
                toast.error(state.removeMessage.message, { onClose: navigate("/") });
            }
        }
    }

    let GA_addCredential = (path) => {
        // GA4 Btn for new credential
        GoogleAnalytics("new_credential_" + path + "_btn", "User");
    };

    function rejectedCredentialOfferHandler() {
        setCloseNotification(true);
        setIsCredentialOfferNotification(false);
        navigate("/");
    }

    return (
        <div className="logged-out page-signin">
            <ToastContainer />

            <section className="content-wrap light-bg">
                <LeftPanel heading={t("Wallet.left-page")} />
                <div className="col-content w-60">
                    {props.action === "issuanceNotification" && !closeNotification && (
                        <IssuanceNotification
                            init_request={init_request}
                            onSuccess={onSuccessAction}
                        />
                    )}
                    {isCredentialOfferNotification && !closeNotification && (
                        <CredentialOfferNotification onReject={rejectedCredentialOfferHandler} />
                    )}
                    {props.action === "credentialNotification" && (
                        <CredentialNotification toastMessage={toastMessage} />
                    )}
                    {!isCredentialOfferNotification && !closeNotification && (
                        <>
                            {loading ? (
                                <></>
                            ) : credentials.length === 0 ? (
                                <div className="messages">
                                    <p className="light">{t("Wallet.credential-nodata")}</p>
                                    <h2 className="m-hide">{t("Wallet.credentialTital-nodata")}</h2>
                                    <Link to="/explorer">
                                        <button
                                            className="btn-primary"
                                            style={{ width: "200px" }}
                                            onClick={() => {
                                                GA_addCredential("basic");
                                            }}
                                        >
                                            {t("Wallet.addCredential-btn")}
                                        </button>
                                    </Link>
                                </div>
                            ) : (
                                <>
                                    <div className="messages m-hide">
                                        <p className="light">{t("Wallet.credentialLight")}</p>
                                        <h2>{t("Wallet.credentialTitle")}</h2>
                                    </div>
                                    <div className="container">
                                        <div className="credentials-container">
                                            {credentials.map((vc, index) => (
                                                <Credential
                                                    credential={vc}
                                                    key={index}
                                                    id={index + 1}
                                                    image="html/img/card_no-icon_purple.svg"
                                                    publishList={publishList}
                                                    onboarded={props.onboarded}
                                                    userEmail={props.userEmail}
                                                />
                                            ))}
                                        </div>

                                        <AboutPortfolio
                                            onboarded={props.onboarded}
                                            style="wallet"
                                        />
                                    </div>
                                </>
                            )}
                        </>
                    )}

                    <Link to="/explorer">
                        <div className="add m-hide">
                            <img
                                src="html/img/plus.svg"
                                alt="Add new"
                                onClick={() => {
                                    GA_addCredential("plus");
                                }}
                            />
                        </div>
                    </Link>
                </div>
                <FooterText style="m-show" />
            </section>
        </div>
    );
}
