import { combineReducers } from "redux";
import issuers from "./issuers";
import ebsiSchemas from "./ebsiSchemas";
import schemas from "./schemas";
import credentials from "./credentials";
import credentialDetails from "./credentialDetails";
import issuersForSchema from "./issuersForSchema";
import brands from "./brands";

export default combineReducers({
    issuers,
    schemas,
    ebsiSchemas,
    credentials,
    credentialDetails,
    issuersForSchema,
    brands
  });