import React, { useEffect, useState } from "react";
import LeftPanel from "../LeftPanel/LeftPanel";
import SimpleProofCredentials from "./simpleProofCredentials";
import EbsiCredentials from "./ebsiCredentials";
import SearchAutocomplete from "./searchAutocomplete";
import CredentialCard from "./credentialCard";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import config from "../../config";
import IssuanceNotification from "../Notification/issuanceNotification";
import CredentialNotification from "../Notification/credentialNotification";
import Cookies from "js-cookie";
import FooterText from "../Footer/Footer";
import { useTranslation } from 'react-i18next';

function CredentialExplorer(props) {
    const { t } = useTranslation();
    const [brandData, setBrandData] = useState([]);
    const [selectedBrandIndex, setSelectedBrandIndex] = useState(-1);
    const [brandIsSelected, setBrandIsSelected] = useState(false);
    const [factoriesData, setFactoriesData] = useState([]);
    const [selectedFactoryIndex, setSelectedFactoryIndex] = useState(-1);
    const [factoryIsSelected, setFactoryIsSelected] = useState(false);
    const [factoriesForBrand, setFactoriesForBrand] = useState([]);

    const [isIssuanceNotification, setIsIssuanceNotification] = useState(false);

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    // Code for verifing credential if user does not have credential required
    const [params] = useSearchParams();
    const autoSelectType = params.get('type');

    // Check if user is logged in and handle redirection
    let cookie = Cookies.get(config.cookieName);
    if (!cookie) {
        if (props.action === "issuanceNotification") {
            window.location.href =
                "/sign-in?redirect_uri=" +
                window.location.origin +
                "/initiate_issuance?" +
                encodeURIComponent(searchParams.toString());
        } else if (props.action === "credentialNotification") {
            window.location.href =
                "/sign-in?redirect_uri=" +
                window.location.origin +
                "/credential?" +
                encodeURIComponent(searchParams.toString());
        } else if (props.action === "shareCredentialNotification") {
            window.location.href =
                "/sign-in?redirect_uri=" +
                window.location.origin +
                "/saveCredential?" +
                encodeURIComponent(searchParams.toString());
        } else {
            window.location.href = "/sign-in";
        }
    }

    useEffect(() => {
        async function fetchData() {
            try {
                const data = await getBrands();
                setBrandData(data);
            } catch (error) {
                console.error("Error:", error);
            }
        }
        fetchData();
        getFactories();
        if (props.action === "issuanceNotification")
            setIsIssuanceNotification(true);
    }, []);

    const [preselectedBrand, setPreselectedBrand] = useState(false);
    const [preselectedType, setPreselectedType] = useState();

    useEffect(() => {
        if (brandData.length > 0) {
            // Check for type to autoselect
            if(autoSelectType !== null){
                console.log("autoselect ",autoSelectType);

                factoriesData.forEach((element, index) => {
                    let name = element.name.replaceAll(" ","");
                    console.log(name);
                    if(name === autoSelectType){
                        setPreselectedType(element.name);
                        console.log("hit ",index);
                        onFactorySelectedHandler(index)
                        onBrandSelectedHandler(-1);
                    }
                });
            } else {
                onBrandSelectedHandler(0);
            }
        }
        setPreselectedBrand(true);
    }, [brandData]);

    useEffect(() => {
         // Check for type to autoselect
         if(autoSelectType !== null){
            console.log("autoselect ",autoSelectType);

            factoriesData.forEach((element, index) => {
                let name = element.name.replaceAll(" ","");
                console.log(name);
                if(name === autoSelectType){
                    setPreselectedType(element.name);
                    console.log("hit ",index);
                    onFactorySelectedHandler(index)
                    onBrandSelectedHandler(-1);
                }
            });
        }
    }, [factoriesData])
    
    async function getBrands() {
        return new Promise((resolve) => {
            fetch(config.trustFrameworkApi + "/trustFramework?type=brands")
                .then((response) => response.json())
                .then((data) => {
                    let brands = [];
                    Object.keys(data).map((key, index) => {
                        let brand = {
                            id: key,
                            name: data[key].display.name,
                            scopes: data[key].scopes,
                            credential_url: data[key].credential_issuer,
                        };
                        return (brands[index] = brand);
                    });
                    resolve(brands);
                });
        });
    }

    function onBrandSelectedHandler(index) {
        //setPreselectedBrand(false);
        clearFactoriesAutocomplete();
        setBrandIsSelected(true);
        if (index !== -1) {
            getBrand(index);
        } else {
            setSelectedBrandIndex(-1);
            setBrandIsSelected(false);
        }
    }

    const [noCredentialsMessage, setNoCredentialsMessage] = useState("");
    async function getBrand(index) {
        const id = brandData[index].id;
        setSelectedBrandIndex(index);
        fetch(config.trustFrameworkApi + "/trustFramework?type=brand&id=" + id)
            .then((response) => response.json())
            .then((data) => {
                const prettyNames = Object.keys(data.scopes).map((key) => {
                    let factory = {
                        id: id,
                        name: data.scopes[key].credential_name,
                        scope: data.scopes[key].scope,
                        credential_url: data.credential_issuer,
                    };
                    return factory;
                });
                setFactoriesForBrand(prettyNames);

                if (prettyNames.length === 0) {
                    setNoCredentialsMessage(
                        t("CredentialExplorer.noCredential-msg")
                    );
                }
            });
    }

    async function getFactories() {
        fetch(config.trustFrameworkApi + "/trustFramework?type=factories")
            .then((response) => response.json())
            .then((data) => {
                const factories = [];
                Object.keys(data).map((key, index) => {
                    let factory = {
                        id: key,
                        name: data[key].credential_name,
                    };
                    return (factories[index] = factory);
                });
                setFactoriesData(factories);

            });
    }

    function onFactorySelectedHandler(index) {
        setPreselectedBrand(false);
        clearBrandsAutocomplete();
        setSelectedFactoryIndex(index);
        setFactoryIsSelected(true);
        setNoCredentialsMessage("");

        if (index !== -1) {
            getFactory(index);
        } else {
            setSelectedFactoryIndex(-1);
            setFactoryIsSelected(false);
        }
    }

    const [brandsForFactory, setBrandsForFactory] = useState([]);

    async function getFactory(index) {
        console.log("index" , index,factoriesData );
        const id = factoriesData[index].id;
        fetch(config.trustFrameworkApi + "/trustFramework?type=factory&id=" + id)
            .then((response) => response.json())
            .then((data) => {

                const prettyNames = Object.keys(data).map((key) => {
                    let brandForFactory = {
                        credentialName: data[key].details.credential_name,
                        issuer: data[key].display.name,
                        issuerId: key,
                        scope: data[key].details.scope,
                        credential_url: data[key].credential_issuer,
                    };
                    return brandForFactory;
                });
                setBrandsForFactory(prettyNames);
            });
    }

    function selectedBrandIssueHandler(name, index) {
        //console.log(brandData[selectedBrandIndex]);
        const url =
            '/initiate_issuance?credential_offer={"credential_issuer":"' +
            brandData[selectedBrandIndex].credential_url +
            '", "scope":"' +
            factoriesForBrand[index].scope +
            '","brand":"' +
            brandData[selectedBrandIndex].id +
            '", "credential_name":"' +
            name +
            '", "issuer_name":"' +
            brandData[selectedBrandIndex].name +
            '", "identifier":"' +
            props.userEmail +
            '"}';

        window.location.href = url;
    }

    function selectedFactoryIssueHandler(name, index) {
        const url =
            '/initiate_issuance?credential_offer={"credential_issuer":"' +
            brandsForFactory[index].credential_url +
            '", "scope":"' +
            brandsForFactory[index].scope +
            '","brand":"' +
            brandsForFactory[index].issuerId +
            '", "credential_name":"' +
            name +
            '", "issuer_name":"' +
            brandsForFactory[index].issuer +
            '", "identifier":"' +
            props.userEmail +
            '"}';

        window.location.href = url;
    }

    const [resetBrandsKey, setResetBrandsKey] = useState(0);
    const [resetFactoriesKey, setResetFactoriesKey] = useState(0);
    function clearBrandsAutocomplete() {
        setResetBrandsKey(resetBrandsKey + 1);
        setFactoriesForBrand([]);
    }
    function clearFactoriesAutocomplete() {
        setResetFactoriesKey(resetFactoriesKey + 1);
        setBrandsForFactory([]);
    }

    // Write all params to object init_request
    const init_request = {};
    searchParams.forEach((value, key) => {
        init_request[key] = value;
    });

    function onRejectHandler() {
        setIsIssuanceNotification(false);
        navigate("/explorer");
    }

    return (
        <div className="logged-out page-signin">
            <section className="content-wrap light-bg">
                <LeftPanel heading={t("CredentialExplorer.left-page")} />
                <div className="col-content w-60">
                    {isIssuanceNotification && (
                        <IssuanceNotification
                            init_request={init_request}
                            onReject={onRejectHandler}
                        />
                    )}
                    {!isIssuanceNotification && (
                        <div className="container">
                            <div className="messages m-hide">
                                <p className="light">{t("CredentialExplorer.titleLight")}</p>
                                <h2>{t("CredentialExplorer.title")}</h2>
                                {/* <p>Start by choosing the predefined credential</p>
                                <p>Choose credential type</p> */}
                            </div>

                            {/* <EbsiCredentials /> */}
                            {/* <p className="messages" style={{marginTop:"3rem"}}>
                                or search specific credential
                            </p> */}

                            <hr style={{ color: "black" }}></hr>

                            {/* <p className="messages" style={{marginTop:"3rem"}}>
                                Find more credentials
                            </p> */}
                            {brandData && (
                                <div className="search-container">
                                    <SearchAutocomplete
                                        preselected={preselectedBrand && autoSelectType === null ? "MyNextID Issuer": undefined}
                                        data={brandData}
                                        onSelected={onBrandSelectedHandler}
                                        label={t("CredentialExplorer.select-label")}
                                        resetKey={resetBrandsKey}
                                    />
                                    <SearchAutocomplete
                                        preselected={preselectedType}
                                        data={factoriesData}
                                        label={t("CredentialExplorer.select-label-1")}
                                        onSelected={onFactorySelectedHandler}
                                        resetKey={resetFactoriesKey}
                                    />
                                </div>
                            )}
                            {brandIsSelected && (
                                <div className="explorer">
                                    {factoriesForBrand.map((factory, index) => (
                                        <CredentialCard
                                            key={index}
                                            id={index}
                                            name={factory.name}
                                            image="html/img/card_no-icon_purple.svg"
                                            issuer={brandData[selectedBrandIndex].name}
                                            onSelect={selectedBrandIssueHandler}
                                        />
                                    ))}
                                    {factoriesForBrand.length === 0 && (
                                        <span className="error">{noCredentialsMessage}</span>
                                    )}
                                </div>
                            )}
                            {factoryIsSelected && (
                                <div className="explorer">
                                    {brandsForFactory.map((brand, index) => (
                                        <CredentialCard
                                            key={index}
                                            id={index}
                                            image="html/img/card_no-icon_purple.svg"
                                            name={brand.credentialName}
                                            issuer={brand.issuer}
                                            onSelect={selectedFactoryIssueHandler}
                                        />
                                    ))}
                                    {factoriesForBrand.length === 0 && (
                                        <span className="error">{noCredentialsMessage}</span>
                                    )}
                                </div>
                            )}
                            {/* <SimpleProofCredentials/> */}
                        </div>
                    )}
                </div>
                <FooterText style="m-show" />
            </section>
        </div>
    );
}

export default CredentialExplorer;
