import http from "../issuerList-db";

class SchemasDataService {
  getByIssuerId(issuerId) {
    return http.get(`/issuer/${issuerId}/schema`);
  }

  getAll() {
    return http.get(`/schema`);
  }
}

export default new SchemasDataService();
