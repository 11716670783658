import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CredentialCard from "../CredentialExplorer/credentialCard";
import GoogleAnalytics from "../GA";
import config from "../../config";
import { useTranslation } from "react-i18next";
import tick from "../../img/tick.png";

function CredentialOfferNotification(props) {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState("Loading...");

    const [searchParams] = useSearchParams();

    // Write all params to object init_request
    const init_request = {};
    searchParams.forEach((value, key) => {
        init_request[key] = value;
    });

    const [issuanceData, setIssuanceData] = useState({});
    useEffect(() => {
        setIsLoading(true);

        //const url = new URL(data.credential_issuer);
        /* const url = new URL("http://localhost:8000/api/v1/test");
        let brandId = "";
        if (url.pathname != "" && url.pathname != "/") {
            brandId = "/" + url.pathname.split("/").pop();
        }

        console.log("brandId: ", brandId); */

        fetch(init_request.credential_offer_uri)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(
                        t("CredentialOfferNotification.throwErrorA")
                    );
                }
                return response.json();
            })
            .then((data) => {
                const url = new URL(data.credential_issuer);
                //const url = new URL("http://localhost:8000/dmSxJHP84CSC");
                let brandId = "";
                if (url.pathname != "" && url.pathname != "/") {
                    brandId = "/" + url.pathname.split("/").pop();
                }

                

                if (data.credentials[0].types != null) {
                    let tempUrl =
                        url.origin +
                        "/.well-known/openid-configuration" +
                        brandId;
                    console.log("Well-known URL: ", tempUrl);
                    fetch(tempUrl)
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error(
                                    t("CredentialOfferNotification.throwErrorB")
                                );
                            }
                            return response.json();
                        })
                        .then((issuerMetadata) => {
                            let types = data.credentials[0].types;

                            if (issuerMetadata.credential_supported != null) {
                                issuerMetadata.credential_supported.map(
                                    (credential) => {
                                        if (
                                            JSON.stringify(types) ===
                                            JSON.stringify(credential.types)
                                        ) {
                                            setIssuanceData({
                                                issuer: issuerMetadata.display
                                                    .name,
                                                credential:
                                                    credential.display.name,
                                            });
                                        }
                                    }
                                );
                            } else {
                                types.map((type) => {
                                    let configurations =
                                        issuerMetadata.credential_configurations_supported;
                                    // check if type is a key in configurations object
                                    if (configurations.hasOwnProperty(type)) {
                                        setIssuanceData({
                                            issuer: issuerMetadata.display[0]
                                                .name,
                                            credential:
                                                configurations[type].display[0]
                                                    .name,
                                        });
                                    }
                                });
                            }
                        });
                }
                setIsLoading(false);
            });
    }, []);

    // On user consent with issuance
    function clickAcceptHandler() {
        // GA4 Creation of new credential approved
        GoogleAnalytics("created_new_credential", "User");

        let linkDestination =
            window.location.origin +
            "/api/v1/credential_offer?" +
            searchParams.toString();
        try {
            window.location.href = linkDestination;
        } catch (error) {
            console.log(error);
        }
    }

    function clickRejectHandler() {
        // GA4 Creation of new credential approved
        GoogleAnalytics("rejected_creation_of_new_credential", "User");
        if (
            issuanceData.hasOwnProperty("credential") &&
            (issuanceData.credential == "eID Credential" ||
                issuanceData.credential == "Travel Authorization Credential" ||
                issuanceData.credential == "E-Receipt Credential")
        ) {
            window.location.href = searchParams.get("redirect_to");
        }
        props.onReject();
    }
    return (
        <div className="container">
            {/*{isLoading && 
                <div className="messages">
                    <h2>{message}</h2>
            </div>
            }*/}
            {!isLoading && (
                <>
                    {issuanceData.hasOwnProperty("credential") &&
                    (issuanceData.credential == "eID Credential" ||
                        issuanceData.credential ==
                            "Travel Authorization Credential" ||
                        issuanceData.credential == "E-Receipt Credential") ? (
                        <div className="messages">
                            <h3>ACCEPT THE CREDENTIAL</h3>
                            <p id="e-id-description">
                                The {issuanceData.issuer} issued your{" "}
                                {issuanceData.credential == "eID Credential"
                                    ? "eID"
                                    : issuanceData.credential ==
                                      "Travel Authorization Credential"
                                    ? "Travel Authorization"
                                    : "E-Receipt Credential"}
                                . Please accept the issued credential in order
                                to store it in your wallet.
                            </p>
                            <button
                                className="btn btn-primary"
                                style={{ width: "190px", marginRight: "20px" }}
                                onClick={clickAcceptHandler}
                            >
                                {t("CredentialOfferNotification.accept-btn")}
                            </button>
                            <button
                                className="btn btn-secondary"
                                style={{ width: "190px" }}
                                onClick={clickRejectHandler}
                            >
                                {t("CredentialOfferNotification.reject-btn")}
                            </button>

                            <p className="e-id-subtext">
                                You will be automatically redirected to the{" "}
                                {issuanceData.credential == "eID Credential"
                                    ? "eID"
                                    : issuanceData.credential ==
                                      "Travel Authorization Credential"
                                    ? "DHS"
                                    : "Webshop"}{" "}
                                portal.
                            </p>
                        </div>
                    ) : (
                        <div className="messages">
                            <p className="light">
                                {t("CredentialOfferNotification.title")}
                            </p>
                            <br />
                            <CredentialCard
                                name={issuanceData.credential}
                                issuer={issuanceData.issuer}
                                disableClick={true}
                            />

                            <p>
                                "{issuanceData.credential}"{" "}
                                {t("CredentialOfferNotification.subtitle")}{" "}
                                {issuanceData.issuer}
                            </p>

                            <button
                                className="btn btn-primary"
                                style={{ width: "190px", marginRight: "20px" }}
                                onClick={clickAcceptHandler}
                            >
                                {t("CredentialOfferNotification.accept-btn")}
                            </button>
                            <button
                                className="btn btn-secondary"
                                style={{ width: "190px" }}
                                onClick={clickRejectHandler}
                            >
                                {t("CredentialOfferNotification.reject-btn")}
                            </button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
export default CredentialOfferNotification;
