import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function ShareCredentialNotification(props) {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    // Write all params to object init_request
    const init_request = {};
    searchParams.forEach((value, key) => {
        init_request[key] = value;
    });

    // Validate params
    /*   if(init_request.credential === undefined) {
        props.onCredentialAction();
      } */

    function clickHandler(choice) {
        props.onCredentialAction();
    }

    function clickAcceptHandler() {
        // Call API to save credential to Vault

        props.onCredentialAction();
    }

    function clickRejectHandler() {
        // Call API to NOT save credential to Vault

        props.onCredentialAction();
    }

    return (
        <div className="logged-out page-signin">
            <div className="popup-overlay">
                <div className="popup">
                    <div>
                        <p style={{ fontSize: "20px", fontWeight: "400" }}>
                            {t("ShareCredentialNotification.title")} <br></br><strong>{init_request.verifier}</strong>
                            <br></br>
                            {t("ShareCredentialNotification.desc").replace("{{credential}}",init_request.credential)}
                            {/*wants you to share credential {init_request.credential}. Do you want to share it?*/}
                        </p>
                        <div className="popup-actions">
                            <button className="btn btn-primary" onClick={clickHandler}>
                            {t("ShareCredentialNotification.accept-btn")}
                            </button>
                            <button className="btn btn-secondary" onClick={clickHandler}>
                            {t("ShareCredentialNotification.reject-btn")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShareCredentialNotification;
