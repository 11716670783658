function CredentialCard(props) {

    function returnData() {
        if (props.disableClick) {
            // Do nothing
        } else {
            props.onSelect(props.name, props.id);
        }
    }

    return (
        <div className="explorer-card" style={props.style} onClick={returnData}>
            <div className="container">
                <p className="item subtitle">{props.name}</p>
                <p className="item title">{props.identifier}</p>
                <p className="item">{props.issuer}</p>
            </div>
        </div>
    );
}

export default CredentialCard;
