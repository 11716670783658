import FooterText from "../Footer/Footer";
import React, { useEffect } from "react";

function LeftPanelNotifications(props) {
  return (
    <div className="col-visual-notifications w-40">
        <div className="visual-heading">
          <img src="html/img/wallet-icon-white.svg" alt="wallet-icon" />          
          <h1 style={{fontSize: props.fontSize}}>{props.heading}</h1>
        </div>
      <FooterText style="m-hide"/>
    </div>
  );
}

export default LeftPanelNotifications;
