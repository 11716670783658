import { use } from "i18next";
import FooterText from "../Footer/Footer";
import React, { useEffect, useState } from "react";

function LeftPanel(props) {
  const [className, setClassName] = useState("col-visual w-40");
  useEffect(() => {
    if (props.isInspector) {
      setClassName("col-visual-inspector w-40");
    }
  }, [props.className]);
  return (
    <div className={className}>
      <div className="visual-heading">
        <img src="html/img/wallet-icon-white.svg" alt="wallet-icon" />
        <h1 style={{ fontSize: props.fontSize }}>{props.heading}</h1>
      </div>
      <FooterText style="m-hide" />
    </div>
  );
}

export default LeftPanel;
