import React, { useState, useEffect } from "react";
import "./App.css";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    useNavigate,
} from "react-router-dom";
import Signin from "./components/Signin/sign-in";
import Wallet from "./components/Wallet/wallet";
import DeferredNotification from "./components/Notification/deferredNotification";
import TermsOfService from "./components/Terms&Policy/TermsOfService";
import PrivacyPolicy from "./components/Terms&Policy/PrivacyPolicy";
import Cookies from "js-cookie";
import config from "./config";
import CredentialExplorer from "./components/CredentialExplorer/credentialExplorer";
import Details from "./components/Details/details";
import Navigation from "./components/Navigation/navigation";
import QrCodeReader from "./components/QrCode/qrCodeReader";
import VerifierCredentialShare from "./components/Verifier/verifierCredentialShare.js";
import Verifier from "./components/Verifier/verifier.js";
import VerifierResults from "./components/Verifier/verifierResults.js";
import ReactGA from "react-ga4"; // Google Analytic
import { ContactlessOutlined } from "@mui/icons-material";
import { useTranslation } from 'react-i18next';
import InspectorApp from './components/InspectorApp/InspectorApp.js'
import InspectorResult from './components/InspectorApp/InspectorResult.js'

ReactGA.initialize(config.ga_id);

function App() {
    //Check if user is logged inf
    const { t } = useTranslation();
    let cookie = Cookies.get(config.cookieName);

    const [onboarded, setOnboarded] = useState(null);
    const [userEmail, setUserEmail] = useState(null);

    let isCookieSet = false;
    if (cookie) {
        isCookieSet = true;
        getEmail();
    }

    // Check if user got authorized credential
    const [isLogged, setIsLogged] = useState(isCookieSet);
    const [isVerifier, setIsVerifier] = useState(false);

    useEffect(() => {

        fetch(config.getAuthorizedVerifierCred)
            .then(async (response) => {
                // check for error response
                if (!response.ok) {
                    const error = await response.text();
                    return Promise.reject(error);
                } else {
                    let data = await response.json();
                    if (data.length > 0) {
                        setIsVerifier(true);
                    }
                }
            })
            .catch((error) => {
                console.error("Error in API Issue: ", error);
            });

    }, []);

    function onLoginHandler() {
        setIsLogged(true);
    }

    // Is user onboarded on Portfolio
    useEffect(() => {
        fetch(config.portfolioOnboarding)
            .then(async (response) => {
                // check for error response
                if (!response.ok) {
                    const error = await response.text();
                    return Promise.reject(error);
                } else {
                    let data = await response.text();
                    if (data === "true\n") data = true;
                    else data = false;
                    setOnboarded(data);
                }
            })
            .catch((error) => {
                console.error("Error in API Issue: ", error);
            });
    }, []);


    function getEmail() {
        fetch(config.walletUser).then(async (response) => {
            // check for error response
            if (!response.ok) {
                const error = await response.text();
                console.log("Error in API Issue: ", error);
            } else {
                let data = await response.text();
                setUserEmail(JSON.parse(data).email);
            }
        });
    }

    return (
        <Router>
            <Navigation isLogged={isLogged} onboarded={onboarded} isVerifier={isVerifier} userEmail={userEmail} />
            <div className="App">
                <Routes>
                    {!isLogged && (
                        <Route path="/" element={<Signin onLogin={onLoginHandler} />} />
                    )}
                    {isLogged && (
                        <Route path="/" element={<Wallet onboarded={onboarded} userEmail={userEmail} />} />
                    )}
                    <Route path="/sign-in" element={<Signin />} />
                    <Route path="/wallet" element={<Wallet onboarded={onboarded} userEmail={userEmail} />} />
                    <Route
                        path="/explorer"
                        element={<CredentialExplorer userEmail={userEmail} />}
                    />
                    <Route path="/details" element={<Details onboarded={onboarded} />} />
                    <Route
                        path="/initiate_issuance/*"
                        element={<CredentialExplorer action="issuanceNotification" />}
                    />
                    <Route
                        path="/credential_offer/*"
                        element={<Wallet action="credentialOfferNotification" />}
                    />
                    <Route
                        path="/credential"
                        element={
                            <Wallet action="credentialNotification" onboarded={onboarded} />
                        }
                    />
                    <Route
                        path="/shareCredential"
                        element={
                            <Wallet
                                action="shareCredentialNotification"
                                onboarded={onboarded}
                            />
                        }
                    />
                    <Route path="/notifications" element={<DeferredNotification />} />
                    <Route
                        path="/cb"
                        element={<Wallet action="callback" onboarded={onboarded} />}
                    />

                    <Route path="/qrCodeReader" element={<QrCodeReader />} />
                    <Route path="/response_result" element={<VerifierResults />} />
                    <Route path="/verifier" element={<Verifier />} />
                    <Route path="/authorize" element={<VerifierCredentialShare userEmail={userEmail} />} />

                    <Route path="/termsOfService" element={<TermsOfService />} />
                    <Route path="/privacyPolicy" element={<PrivacyPolicy />} />

                    <Route path="/inspectorApp" element={<InspectorApp />} />
                    <Route path="/inspectorAppResult" element={<InspectorResult />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
