import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import config from "../../config";
import SchemaForm from "./schemaForm";
import Credential from "../Credential/credential";
import DetailsCredential from "../Credential/detailsCredential";
import Switch from "@mui/material/Switch";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import Popup from "../Popup/popup";
import SharePopup from "../Popup/sharePopup";
import { ToastContainer, toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DataList from "./dataList";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import FooterText from "../Footer/Footer";
import AboutPortfolio from "../Portfolio/aboutPortfolio";
import GoogleAnalytics from "../GA";
import { useTranslation } from "react-i18next";

function Details(props) {
    const { t } = useTranslation();
    // Check if user is logged in and handle redirection
    let cookie = Cookies.get(config.cookieName);
    if (!cookie) {
        window.location.href = "/sign-in";
    }

    const { state } = useLocation();
    const passedCredential = state.credential;

    // disable delete button (anti spam )
    const [disableBtn, setDisableBtn] = useState(false);

    // Toggle switch handlers
    const [popupActive, setPopupActive] = useState(false);

    const navigate = useNavigate();

    // Get credential from backend
    const [credentialDetails, setCredentialDetails] = useState();

    useEffect(() => {
        getSelectedCredential().then((response) => {
            response.json().then((text) => {
                setCredentialDetails(text);
            });
        });
        checkIfCredentialIsPublic();
    }, []);

    async function getSelectedCredential() {
        const response = await fetch(config.getCredentialUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                key: passedCredential.name,
            }),
        });
        return response;
    }

    // Check if credential is public
    async function checkIfCredentialIsPublic() {
        fetch(config.publicEndpoint)
            .then((response) => response.json())
            .then((data) => {
                data.map((item) => {
                    if (item.name === passedCredential.name) {
                        setChecked(true);
                        setToggleLabel("Public");
                    }
                });
            });
    }

    // Remove credential handlers
    const [removeMessage, setRemoveMessage] = useState(false);
    function toggleRemovePopup() {
        setRemoveMessage(!removeMessage);
        setShowAboutPortfolio(!showAboutPortfolio);
    }

    async function onRemoveCredentialPopupAction(event) {
        setDisableBtn(true);
        if (event.target.value === "true") {
            try {
                const params = new URLSearchParams({
                    credential: passedCredential.name,
                });

                const response = await fetch(
                    config.deleteCredentialUrl + "?" + params,
                    {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization:
                                t("Details.credential-popup-auth") +
                                " " +
                                cookie,
                        },
                    }
                );

                if (response.status === 200) {
                    navigate(`/wallet`, {
                        state: {
                            removeMessage: {
                                action: "success",
                                message: t("Details.credential-mgs"),
                            },
                        },
                    });
                } else {
                    navigate(`/wallet`, {
                        state: {
                            removeMessage: {
                                action: "error",
                                message:
                                    t("Details.credential-msg-error") +
                                    " " +
                                    response.status +
                                    " " +
                                    response.statusText +
                                    ".",
                            },
                        },
                    });
                }
            } catch (error) {
                toast.error(error);
            }
        } else {
            setDisableBtn(false);
            toggleRemovePopup();
        }
    }

    function removeCredential() {
        // GA4 Delete credential
        GoogleAnalytics("delete_credential_btn", "User");

        toggleRemovePopup(true);
        setShowAboutPortfolio(!showAboutPortfolio);
    }

    const [showAboutPortfolio, setShowAboutPortfolio] = useState(true);

    // Share credential handlers
    const [shareMessage, setShareMessage] = useState(false);
    const [shareLink, setShareLink] = useState("Error generating link.");

    function shareCredential() {
        // GA4 Btn for adding cred from portfolio
        GoogleAnalytics("publish_credential_details", "User");

        makeCredentialPublic();
        //setShareMessage(true);
        setChecked(true);
    }

    function unShareCredential() {
        // GA4 Btn for removing cred from portfolio
        GoogleAnalytics("unpublish_credential_details", "User");

        removeFromPublicList();
        setChecked(false);
    }

    async function cancelPublishAction() {
        let url =
            config.publicEndpoint + "?credential_id=" + passedCredential.name;
        const response = await fetch(url, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        });
        setChecked(false);
    }

    function onShareCredentialPopupAction() {
        setShareMessage(false);
    }

    async function makeCredentialPublic() {
        let url =
            config.publicEndpoint + "?credential_id=" + passedCredential.name;
        await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                //console.log(data);
                if (data.code === "001") {
                    //toast.error("Error making credential public!");
                    triggerPopup();
                } else {
                    toast.success(t("Details.toast-success"));
                    setShareLink(data);
                    setAlignment("public");
                }
            })
            .catch((error) => {
                toast.error(t("Details.toast-error"));
                console.log(error);
            });
    }

    const [checked, setChecked] = useState(false);
    const [toggleLabel, setToggleLabel] = useState("Private");
    const [portofolioOnboardingLink, setPortofolioOnboardingLink] = useState(
        config.portfolioLanding
    );
    const [alignment, setAlignment] = useState("private");

    const toggleSwitch = (event, newAlignment) => {
        if (newAlignment !== null) {
            // enforce a value
            setAlignment(newAlignment);
            if (newAlignment === "private") {
                removeFromPublicList();
            } else {
                makeCredentialPublic();
            }
        }
    };

    async function removeFromPublicList() {
        let url =
            config.publicEndpoint + "?credential_id=" + passedCredential.name;
        const response = await fetch(url, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (response.status === 200) {
            toast.success(t("Details.toast-remove-success"));
        } else {
            toast.error(t("Details.toast-remove-error"));
            //setAlignment("public");
        }
    }

    function onNotOnboardedPopupAction(choice) {
        // GA4 Popup for onbording portfolio
        GoogleAnalytics("onboarding portfolio_details", "User");

        if (choice === "true") {
            window.open(portofolioOnboardingLink, "_blank");
            setPopupActive(!popupActive);
        } else {
            cancelPublishAction();
            setPopupActive(!popupActive);
        }
    }

    function triggerPopup() {
        setPopupActive(!popupActive);
    }

    function copyShareLink() {
        navigator.clipboard.writeText(shareLink);
        toast.info(t("Details.toast-info"));
    }

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 480,
                md: 768,
                lg: 1024,
                xl: 1440,
            },
        },
    });

    const MyToggleButton = styled(ToggleButton)(({ theme }) => ({
        border: "none",
        fontSize: "16px",
        "&.Mui-selected": {
            backgroundColor: "white",
            color: "#231f20",
            borderRadius: "8px",
            transform: "scale(0.90)",
            textTransform: "none",
            fontFamily: "Saira",
            margin: "0",

            "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
            },
        },
        "&.MuiToggleButton-root": {
            textTransform: "none",
            fontFamily: "Saira",
        },
        "&.MuiToggleButtonGroup-grouped:not(:last-of-type)": {
            borderRadius: "8px",
            //[theme.breakpoints: "50px" },
        },
        "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": {
            borderRadius: "8px",
            //width: { sm: "50%" },
        },
    }));

    let GA_addCredential = (path) => {
        // GA4 Btn for new credential
        GoogleAnalytics("new_credential_" + path + "_btn", "User");
    };

    const [europassPopup, setEuropassPopup] = useState(false);
    function sendToEuropass() {
        setEuropassPopup(true);
    }

    function europassProceedAction(choice) {
        if (choice === "true") {
            // call API to send credential to Europass as form-data
            /* let url =
                "https://europa.eu/europass/wallet/api/v2/credentials?userEmail=" +
                state.userEmail +
                "&createTemporalWallet=true&locale=en";
            
            const formData = new FormData();            

            const blob = new Blob([credentialDetails.data.credential_raw], {
                type: "application/jsonld",
            });
            formData.append("_credential", blob, "credential.jsonld");

            fetch(url, {
                method: "POST",
                mode: "no-cors",
                body: formData,
            })
                .then((response) => {
                    console.log("response", response)
                    if (response.status === 200 || response.status === 409) {
                        toast.success("Credential sent to Europass.");
                    } else {
                        toast.error("Credential was not sent to Europass.");
                    }
                    return response.text();
                })
                .then((data) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.error("Error in API Issue: ", error);
                }); */

            fetch(config.depositToEuropass, {
                method: "POST",
                body: JSON.stringify({
                    email: state.userEmail,
                    credential: credentialDetails.data.credential_raw,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data.status === 200) {
                        toast.success("Credential sent to Europass.");
                    } else if (data.status === 409) {
                        toast.warn("Credential already exists in Europass.");
                    } else {
                        toast.error("Credential was not sent to Europass.");
                        console.log("Error in API Issue: ", data.body)
                    }
                })
                .catch((error) => {
                    console.error("Error in API Issue: ", error);
                });
            setEuropassPopup(false);
        } else {
            toast.warn("Credential was not sent to Europass.");
            setEuropassPopup(false);
        }
    }

    return (
        <div className="logged-out page-signin">
            <ToastContainer />
            <section className="content-wrap grey-bg">
                <div className="details-visual w-40">
                    <div className="container-toggle">
                        {/*
                        <ToggleButtonGroup
                        className="toggle"
                        sx={{
                            backgroundColor: "#F0F0F0",
                            color: "#9B9B9B",
                            "&.MuiToggleButtonGroup-root": {
                            borderRadius: "8px",
                            },
                        }}
                        value={alignment}
                        exclusive
                        onChange={toggleSwitch}
                        >
                        <MyToggleButton value="private" id="private">
                            Private
                        </MyToggleButton>
                        <MyToggleButton value="public" id="public">
                            Public
                        </MyToggleButton>
                        </ToggleButtonGroup>
                        */}

                        <DetailsCredential
                            style="details"
                            credential={passedCredential}
                            userEmail={state.userEmail}
                        />
                    </div>
                    {!shareMessage && !removeMessage && (
                        <div className="container">
                            {props.onboarded != null &&
                            props.onboarded == "false\n" ? (
                                <>
                                    <button
                                        className="btn btn-primary row"
                                        onClick={triggerPopup}
                                    >
                                        {t("Details.publish-btn")}
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button
                                        className="btn btn-primary row"
                                        onClick={
                                            checked
                                                ? unShareCredential
                                                : shareCredential
                                        }
                                    >
                                        {checked
                                            ? t("Details.unpublish-btn")
                                            : t("Details.publish-btn")}
                                    </button>
                                </>
                            )}
                            {passedCredential.credential_title ===
                                "European Credential" && (
                                <button
                                    className="btn btn-primary row"
                                    onClick={sendToEuropass}
                                >
                                    {t("Details.send-europass-btn")}
                                </button>
                            )}

                            <button
                                className="btn btn-secondary row"
                                onClick={removeCredential}
                            >
                                {t("Details.delete-btn")}
                            </button>

                            {/* Code to keep above btn max width */}
                            <div style={{ width: "345px" }}></div>
                        </div>
                    )}

                    {removeMessage && (
                        <div className="remove-container">
                            <h2 className="heading">
                                {" "}
                                {t("Details.removeMsg-title")}
                            </h2>
                            <p className="description">
                                {t("Details.removeMsg-desc")}
                            </p>
                            <button
                                disabled={disableBtn}
                                className={"btn btn-primary row"}
                                onClick={onRemoveCredentialPopupAction}
                                value="true"
                            >
                                {t("Details.remove-delete-btn")}
                            </button>
                            <button
                                className="btn btn-secondary row"
                                onClick={toggleRemovePopup}
                                value="false"
                            >
                                {t("Details.remove-cancel-btn")}
                            </button>
                        </div>
                    )}

                    {shareMessage && (
                        <div className="share-container">
                            <h2 className="heading">
                                {t("Details.share-title")}
                            </h2>
                            <img
                                style={{ padding: "0 10px 6px 0" }}
                                src="html/img/copy-icon.svg"
                            ></img>
                            <p className="shareLink" onClick={copyShareLink}>
                                {shareLink}
                            </p>
                            <button
                                className="btn btn-secondary"
                                onClick={onShareCredentialPopupAction}
                            >
                                {" "}
                                {t("Details.share-close-btn")}{" "}
                            </button>
                        </div>
                    )}

                    {showAboutPortfolio && (
                        <AboutPortfolio
                            onboarded={props.onboarded}
                            style="details"
                        />
                    )}

                    <FooterText style="m-hide" />
                </div>
                <div className="details-content w-60">
                    {credentialDetails && (
                        <DataList
                            details={credentialDetails}
                            credentialData={passedCredential}
                        />
                    )}
                    <Link to="/explorer">
                        <div className="add m-hide">
                            <img
                                src="html/img/plus.svg"
                                alt="Add new"
                                onClick={() => {
                                    GA_addCredential("plus");
                                }}
                            />
                        </div>
                    </Link>
                </div>

                {popupActive && (
                    <Popup
                        heading={t("Details.portfolio-title")}
                        description={t("Details.portfolio-desc")}
                        acceptText={t("Details.register-btn")}
                        declineText={t("Details.cancel-btn")}
                        onAction={onNotOnboardedPopupAction}
                    />
                )}

                {europassPopup && (
                    <Popup
                        heading="Send to Europass"
                        description={
                            <span>
                                This credential will be sent to{" "}
                                <a
                                    href="https://europass.europa.eu/en"
                                    target="_blank"
                                >
                                    Europass portfolio
                                </a>{" "}
                                account with e-mail: {state.userEmail} Do you
                                want to proceed?
                            </span>
                        }
                        acceptText="Yes"
                        declineText="Cancel"
                        onAction={europassProceedAction}
                    />
                )}

                <FooterText style="m-show" />
            </section>
        </div>
    );
}

export default Details;
