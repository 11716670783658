import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import config from "../../config";
import "../../App.css";

import { ToastContainer, toast } from "react-toastify";
import LeftPanel from "../LeftPanel/LeftPanel";
import FooterText from "../Footer/Footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { useTranslation } from 'react-i18next';

export default function VerifierResults() {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const [credentialSubject, setCredentialSubject] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        // get state from url
        const responseCode = searchParams.get("responseCode");
        const url =
            config.serverUrl +
            config.responseResultApi +
            "?responseCode=" +
            responseCode;
        //console.log(url);
        // HTTP GET to get the credentialSubject
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                //data = JSON.parse(data);
                setCredentialSubject(data.credential_subject);
            });
    }, []);

    function formatKey(key) {
        return key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " ");
    }

    return (
        <div className="logged-out page-signin">
            <ToastContainer />
            <section className="content-wrap light-bg">
                <LeftPanel heading={t("VerifierResults.left-page")} />
                <div className="col-content w-60">
                    <div className="messages">
                        <h2> {t("VerifierResults.title")}</h2>
                    </div>

                    <div className="container">
                        <div className="datalist-container">
                            {credentialSubject ? (
                                <>
                                    <h3>{t("VerifierResults.form-text-1")}</h3>
                                    <h3>
                                        <FontAwesomeIcon
                                            icon={faCircleCheck}
                                            style={{ color: "#368737" }}
                                            size="3x"
                                        />
                                    </h3>
                                    <table className="table">
                                        <tbody>
                                            {Object.keys(credentialSubject).map((key, index) => {
                                                if (typeof credentialSubject[key] === "object") {
                                                    return Object.keys(credentialSubject[key]).map(
                                                        (key2) => {
                                                            return (
                                                                <tr key={key2}>
                                                                    <td className="col-1">{formatKey(key2)}</td>
                                                                    <td className="col-2">
                                                                        {credentialSubject[key][key2] ? credentialSubject[key][key2] : "/"}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                    );
                                                } else {
                                                    return (
                                                        <tr key={key}>
                                                            <td className="col-1">{formatKey(key)}</td>
                                                            <td className="col-2">
                                                                {credentialSubject[key] ? credentialSubject[key] : "/"}
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            })}
                                        </tbody>
                                    </table>
                                </>
                            ) : (
                                <>
                                    <h3>{t("VerifierResults.form-text-2")}</h3>
                                    <h3>
                                        <FontAwesomeIcon
                                            icon={faCircleXmark}
                                            style={{ color: "#C51E3A" }}
                                            size="3x"
                                        />
                                    </h3>
                                    <button
                                        className="btn-primary"
                                        onClick={() => {
                                            navigate("/verifier");
                                        }}
                                    >
                                       {t("VerifierResults.start-btn")}
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <FooterText style="m-show" />
            </section>
        </div>
    );
}
