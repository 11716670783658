import config from "../../config";
import React, { useEffect } from "react";
import finalPropsSelectorFactory from "react-redux/es/connect/selectorFactory";

let eventStream = {};
let notifications = {};
let nHandlers = {};

export default function useNotifications(url,isLogged) {

    //let url = props.url;
    if (!notifications[url]) {
        notifications[url] = [];
    }

    if (!nHandlers[url]) {
        nHandlers[url] = [];
    }

    const [n, setN] = React.useState(notifications[url]);

    if (!eventStream[url] && isLogged) {
        eventStream[url] = 1;
        fetch(config.getDeferredNotification + "?active=true")
            .then(async (response) => {
                let text = await response.text();
                if (!response.ok) {
                    throw new Error(text);
                } else {
                    const data = JSON.parse(text);
                    if (data === null) {
                        notifications[url] = [];
                    } else {
                        notifications[url] = data;
                    }
                    nHandlers[url].forEach((setNHandle) => {
                        setNHandle([...notifications[url]]);
                    });

                    // Connect to channel
                    console.log("New sse connection");
                    eventStream[url] = new EventSource(url);
                    eventStream[url].onopen = function () {
                        console.log("Connection open");
                    };
                    eventStream[url].onmessage = function (data) {
                        const dataObj = JSON.parse(data.data);
                        // Adding or deleting notification
                        if (dataObj.active) {
                            notifications[url].unshift(dataObj);
                        } else {
                            let id = dataObj.id;

                            const result = notifications[url].filter(
                                (word) => id !== word.id
                            );
                            notifications[url] = result;
                        }

                        nHandlers[url].forEach((setNHandle) => {
                            setNHandle([...notifications[url]]);
                        });
                    };
                    eventStream[url].onclose = function () {
                        delete eventStream[url];
                    };
                }
            })
            .catch((e) => {
                delete eventStream[url];
            });
        /* .then(() => {
          //console.log("onLoad");
          props.onLoad();
        }); */
    }

    React.useEffect(() => {
        nHandlers[url].push(setN);
        return function () {
            // cleanup
            nHandlers[url] = nHandlers[url].filter(
                (setNHandle) => setNHandle !== setN
            );
        };
    }, []);
    return n;
}
