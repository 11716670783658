import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./navigation.css";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import useNotifications from "./notification";
import LanguageSelector from "../LanguageSelector";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import GoogleAnalytics from "../GA";
import ReactGA from "react-ga4";
import { useTranslation } from 'react-i18next';

function Navigation(props) {

    const { t } = useTranslation();
    const navigate = useNavigate();
    /* const notifications = <useNotifications url={config.deferredNotificationUrl} onLoad={props.onLoad}/>; */
    const notifications = useNotifications(config.deferredNotificationUrl,props.isLogged);

    // hamburger navigation logic
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function logoutHandler() {
        // GA4 Logout
        GoogleAnalytics('logout_nav_btn', 'User');

        window.location.href = config.auth0Logout;
    }

    function loginPortfolio() {
        // GA4 lofin to portfolio navigation
        ReactGA.event({
            action: 'portfolio_nav_btn',
            category: 'User',
        })

        window.open(config.loginPortfolio, "_blank");
    }

    let GA_addCredential = (path) => {
        // GA4 Btn for new credential 
        GoogleAnalytics('new_credential_' + path + '_btn', 'User');
    }

    let GA_actions = (path) => {
        // GA4 Btn for new credential 
        GoogleAnalytics(path + '_icon', 'User');
    }

    return (
        <>
            <Link to="/">
                <img src="html/img/logo_mynextID.svg" alt="" className="logo" />
            </Link>

            {!props.isLogged ? ( <>
               
                <div className="actions lang_selector" >
                    <LanguageSelector logedin={false}/>
                </div>
                
            </>) : (
                <>
                    <div className="actions">
                        <Link to="/explorer">
                            <div className="add m-show">
                                <img src="html/img/plus.svg" alt="Add new" onClick={() => { GA_addCredential("plus") }} />
                            </div>
                        </Link>

                        <div
                            className="notifications"
                            onClick={() => navigate("/notifications")}
                        >
                            {notifications.length > 0 && (
                                <div className="bubble">{notifications.length}</div>
                            )}
                            <img src="html/img/bell.svg" alt="Notifications" />
                        </div>
                        <Link to="/wallet">
                            <div className="wallet-icon">
                                <img src="html/img/wallet.svg" alt="My wallet" onClick={() => { GA_actions("wallet") }} />
                            </div>
                        </Link>

                        {props.onboarded && (
                            <div className="m-hide">
                                <button
                                    className="btn btn-primary-navigation"
                                    onClick={loginPortfolio}
                                >{t("Navigation.portfolio")}</button>
                            </div>
                        )}

                        <LanguageSelector logedin={true}/>
                

                        <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <MenuRoundedIcon fontSize="large" sx={{ color: "#0c0c0c" }} />
                        </IconButton>

                        <Menu
                            MenuListProps={{ "aria-labelledby": "long-button" }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            id="account-menu"
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: "visible",
                                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                    mt: 1.5,
                                    "& .MuiButtonBase-root": {
                                        fontFamily: "Saira",
                                    },
                                    "& .MuiAvatar-root": {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    "&:before": {
                                        content: '""',
                                        display: "block",
                                        position: "absolute",
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: "background.paper",
                                        transform: "translateY(-50%) rotate(45deg)",
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: "right", vertical: "top" }}
                            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                        >
                            <MenuItem style={{ fontWeight: 600 }} disabled >{props.userEmail}</MenuItem>
                            <MenuItem
                                onClick={() => {

                                    // GA4 Btn navigate to wallet 
                                    GoogleAnalytics('wallet_nav_btn', 'User');

                                    navigate("/wallet");
                                }}
                            >{t("Navigation.wallet")}</MenuItem>
                            <MenuItem
                                onClick={() => {

                                    // GA4 Btn navigate to new credential 
                                    GoogleAnalytics('new_credential_explorer_nav_btn', 'User');

                                    navigate("/explorer");
                                }}
                            >{t("Navigation.credentials")}</MenuItem>
                            <div className="m-none">
                                <MenuItem onClick={loginPortfolio}>{t("Navigation.visitPortfolio")}</MenuItem>
                            </div>

                            {props.isVerifier && 
                                <MenuItem onClick={() => navigate("verifier")}>{t("Navigation.verifier")}</MenuItem>
                            }   
                             
                            {props.isVerifier && 
                                <MenuItem onClick={() => navigate("inspectorApp")}>{t("Navigation.inspectorApp")}</MenuItem>
                            }

                            <MenuItem divider={true} onClick={() => navigate("qrCodeReader")}>{t("Navigation.qr")}</MenuItem>

                            <MenuItem onClick={logoutHandler}>{t("Navigation.logout")}</MenuItem>

                        </Menu>
                    </div>
                </>
            )}
        </>
    );
}

export default Navigation;
