import { Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import { ToastContainer, toast } from "react-toastify";
import GoogleAnalytics from "../GA";
import { useTranslation } from 'react-i18next';

export default function Credential(props) {
    const { t } = useTranslation();
    const [isPublic, setPublic] = useState(false);
    let credential = props.credential;
    const navigate = useNavigate();

    function cardClickHandler() {

        GoogleAnalytics('credential_details', 'User');

        if (props.style !== "details") {
            navigate(`/details`, {
                state: { credential: props.credential, userEmail: props.userEmail },
            });
        }
    }

    //------------------------ checkbox for publish / unpublish----------------------------
    useEffect(() => {
        if (props.publishList != undefined) {
            JSON.parse(props.publishList).map((item) => {
                if (item.name === credential.name) {
                    setPublic(true);
                }
            });
        }
    }, [props.publishList]);

    function unShareCredential() {

        // GA4 Btn for removing cred from portfolio
        GoogleAnalytics('portfolio_unpublish_credential_selector', 'User');

        removeFromPublicList();
        setPublic(false);
    }

    function shareCredential() {
        // GA4 Btn for adding cred from portfolio
        GoogleAnalytics('portfolio_publish_credential_selector', 'User');

        makeCredentialPublic();
        //setShareMessage(true);
        setPublic(true);
    }

    async function removeFromPublicList() {
        let url = config.publicEndpoint + "?credential_id=" + credential.name;
        const response = await fetch(url, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (response.status === 200) {
            toast.success(t("Credential.toast-publicList-success"));
        } else {
            toast.error(t("Credential.toast-publicList-error"));
            //setAlignment("public");
        }
    }

    function truncateString(id, maxLength) {
        //const maxLength = 20;
        if (id.length > maxLength) {
            const start = id.substr(0, maxLength - 3);
            //const end = id.substr(id.length - maxLength / 2 + 1);
            return `${start}...`;
        }
        return id;
    }

    async function makeCredentialPublic() {
        let url = config.publicEndpoint + "?credential_id=" + credential.name;
        await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                console.log(data);
                if (data.code === "001") {
                    //setOnboarded(false);
                    toast.error(t("Credential.toast-credPublic-error"));
                } else {
                    toast.success(t("Credential.toast-credPublic-success"));
                }
            })
            .catch((error) => {
                toast.error(t("Credential.toast-credPublic-error"));
                console.log(error);
            });
    }

    //---------------------END for checkbox for publish / unpublish------------------------

    return (
        <>
            <div className="explorer-card-wrap">
                <div
                    className="explorer-card"
                    style={{
                        backgroundImage: `url("html/img/card_no-icon_${credential.bg_color}.svg")`,
                    }}
                    onClick={cardClickHandler}
                >
                    <div className="container">
                        <p className="item subtitle">{credential.credential_title}</p>
                        <p className="item title">
                            {credential.credential_title ===
                                "MyNextID Email Credential (Self)" ? (
                                <strong>{props.userEmail}</strong>
                            ) : (
                                <strong>
                                    {truncateString(credential.credential_identifier, 40)}
                                </strong>
                            )}
                        </p>
                        <div className="item">
                            <p>{t("Credential.form-text-1")}{" "}{truncateString(credential.issuer_title, 20)}</p>
                            {/* <p className="item">Issuer: {credential.issuer_title}</p> */}

                            {/* {credential.expires_in ? (
                <p>Expires: {credential.expires_in.split(" ")[0]}</p>
              ) : (
                credential.issued_at && (
                  <p>Valid from: {credential.issued_at.split(" ")[0]}</p>
                )
              )} */}
                            {credential.valid_from && (
                                <p>{t("Credential.form-text-2")}{" "}{credential.valid_from.split("T")[0]}</p>
                            )}
                            {!credential.valid_from && credential.expires_in && (
                                <p>{t("Credential.form-text-3")}{" "}{credential.expires_in.split("T")[0]}</p>
                            )}
                        </div>
                    </div>
                </div>
                {props.onboarded != null && props.onboarded && (
                    <div className="tooltip round">
                        <input
                            type="checkbox"
                            className="rounded-checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            checked={isPublic}
                            onChange={isPublic ? unShareCredential : shareCredential}
                            style={{ marginTop: "-10px" }}
                        />
                        <span className="tooltiptext">{t("Credential.form-text-4")}{" "}</span>
                    </div>
                )}
            </div>
        </>
    );
}
