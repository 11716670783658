import React, { useState, useEffect, useCallback } from "react";
import LeftPanel from "../LeftPanel/LeftPanel";
import {
    useSearchParams,
    Link,
    useNavigate,
    useLocation,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import config from "../../config";
import { Autocomplete, TextField } from "@mui/material";
import QrCodeScanner from "./qrCode";
import FooterText from "../Footer/Footer";
import { useTranslation } from 'react-i18next';


export default function QrCodeReader(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const notifyError = (err) => toast.error(err, { theme: "colored" });

    const handleChange = (event, value) => {
        //console.log(brands,event,value);
        if (value !== null) {
            navigate(`/company/${value.value}`);
        }
    };

    const [openQrReader, setOpenQrReader] = useState(false);
    function handleQrClick() {
        setOpenQrReader(!openQrReader);
        //navigate(`/qrcode`);
    }

    function onQrScan(result) {
        console.log(result);
    }

    return (
        <div className="logged-out page-signin">
            <ToastContainer />

            <section className="content-wrap light-bg">
                <LeftPanel heading={t("QrCodeReader.left-page")} />
                <div className="col-content w-60">
                    <QrCodeScanner qrBorderSize="100px" onScanResult={onQrScan} />
                </div>
                <FooterText style="m-show" />
            </section>
        </div>
    );
}
