import { RETRIEVE_ISSUERS_FOR_SCHEMA, RESET_STATE } from "../actions/types";

const initialState = [];

function issuerForSchemaReducer(issuersForSchema = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_ISSUERS_FOR_SCHEMA:
      return payload;

    case 'RESET_STATE':
      return initialState;

    default:
      return issuersForSchema;
  }
}

export default issuerForSchemaReducer;
