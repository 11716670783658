import React, { useEffect } from "react";
import CredentialCard from "./credentialCard";
import { retrieveEbsiSchemas } from "../../actions/schemas";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

function EbsiCredentials() {
    const { t } = useTranslation();
    const schemas = useSelector((state) => state.ebsiSchemas);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(retrieveEbsiSchemas(1));
    }, []);

    function issueCredential(name) {
        let link;
        switch (name) {
            case "Student ID":
                link =
                    window.location.origin +
                    "/initiate_issuance?issuer=https%3A%2F%2Fapi-conformance.ebsi.eu%2Fconformance%2Fv2&credential_type=https%3A%2F%2Fapi-conformance.ebsi.eu%2Ftrusted-schemas-registry%2Fv2%2Fschemas%2Fz22ZAMdQtNLwi51T2vdZXGGZaYyjrsuP1yzWyXZirCAHv&conformance=7d377698-1054-486d-8552-67a608c53f3f";
                break;
            case "Diploma":
                link =
                    window.location.origin +
                    "/initiate_issuance?issuer=https%3A%2F%2Fapi-conformance.ebsi.eu%2Fconformance%2Fv2&credential_type=https://api-conformance.ebsi.eu/trusted-schemas-registry/v2/schemas/z6EoWU6KYRjy7mR9VuKecgsNAuBkYGF3H94PdqgcEdQtp&conformance=7d377698-1054-486d-8552-67a608c53f3f";
                break;
            case "Portable document A1":
                link =
                    window.location.origin +
                    "/initiate_issuance?issuer=https%3A%2F%2Fapi-conformance.ebsi.eu%2Fconformance%2Fv2&credential_type=https://api-conformance.ebsi.eu/trusted-schemas-registry/v2/schemas/zGUR7RxCZtwQFSdwow63xF8hP2gMtH55incKsADwrcwzZ&conformance=7d377698-1054-486d-8552-67a608c53f3f";
                break;
            default:
                return;
        }
        window.location.href = link;
    }

    return (
        <div className="row">
            <p className="section-title">{t("ebsiCredentials.title")}</p>
            {schemas.map((credential) => (
                <CredentialCard
                    key={credential.id}
                    name={credential.name}
                    image="html/img/ebsi-white.png"
                    onSelect={issueCredential}
                />
            ))}
        </div>
    );
}

export default EbsiCredentials;
