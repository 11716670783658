// Popup.js
import React from 'react';
import tick from "../../img/tick.png";
import alert from "../../img/alert_2.png";


const Popup = (props) => {

    //console.log(props);

    return (
        <div className="popup-container">

            {/* Is varifier verified?  */}

            {props.popupValid ? 
                (props.popupMode == "payment" ? 
                    <div className="popupDemo" style={{textAlign:"center"}}>
                        <div>
                            <img src={ tick } alt="logo" className="popup_img" />
                        </div>
                        
                        <h2 className='popup_title'>SUCCESS!</h2>
                        <p className='popup_desc'>Your payment was successful.</p>
                        <br/>
                        <p className='popup_desc_small'>You will be automatically redirected to the demo-webshop website.</p>
                    </div>
                    
                
                :
                    <div className="popupDemo" style={{textAlign:"center"}}>
                        <div>
                            <img src={ tick } alt="logo" className="popup_img" />
                        </div>
                        
                        <h2 className='popup_title'>SUCCESS!</h2>
                        <p className='popup_desc'>Your eID credential has been successfully shared with U.S. Customs and Border Protection.</p>
                        <br/>
                        <p className='popup_desc_small'>You will be automatically redirected to the U.S. Customs and Border Protection website.</p>
                    </div>
                )
            
            : 
                (props.popupMode == "payment" ? 
                    <div className="popupDemo" style={{textAlign:"center" }}>
                        <div>
                            <img src={ alert } alt="logo" className="popup_img" />
                        </div>
                        <h2 className='popup_title'>IMPORTANT!</h2>
                        <p className='popup_desc' style={{height:"auto"}}>This webshop couldn’t prove that it’s Shoe Shop; its security certificate is invalid. This may be caused by a misconfiguration or the fact that this webshop is fake.</p>
                        <br/>
                        <p className='popup_desc'>By clicking on Confirm payment you will purchase item(s) at your own risk.</p>
                        <button className="btn_primary btn-popup"  onClick={ props.shareNotVerified }>CONFIRM PAYMENT</button>
                        <br/>
                        <button style={{marginTop: "10px"}} className="btn_primary btn-popup"  onClick={ props.onClose }>CANCEL</button>
                        {/*<button className="btn_primary btn-popup" onClick={props.onClose}>OK</button>*/}
                    </div>
                :
                    <div className="popupDemo" style={{textAlign:"center" }}>
                        <div>
                            <img src={ alert } alt="logo" className="popup_img" />
                        </div>
                        <h2 className='popup_title'>IMPORTANT!</h2>
                        <p className='popup_desc' style={{height:"auto"}}>This verifier couldn’t prove that it’s U.S. Customs and Border Protection; its security certificate is invalid. This may be caused by a misconfiguration or an attacker intercepting your connection.</p>
                        <br/>
                        <p className='popup_desc'>By clicking on Share you will share the Verifiable Credential at your own risk.</p>
                        <button className="btn_primary btn-popup"  onClick={ props.shareNotVerified }>SHARE</button>
                        <br/>
                        <button style={{marginTop: "10px"}} className="btn_primary btn-popup"  onClick={ props.onClose }>CANCEL</button>
                        {/*<button className="btn_primary btn-popup" onClick={props.onClose}>OK</button>*/}
                    </div>
                )
            }
            
        </div>
    );
};

export default Popup;
