import { RETRIEVE_BRANDS, RETRIEVE_BRAND } from "../actions/types";

const initialState = [];

function brandReducer(brands = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_BRANDS:
      return payload;

    case RETRIEVE_BRAND:
      return payload;

    default:
      return brands;
  }
}

export default brandReducer;
