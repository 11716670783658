import { RETRIEVE_ISSUERS } from "../actions/types";

const initialState = [];

function issuerReducer(issuers = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_ISSUERS:
      return payload;

    default:
      return issuers;
  }
}

export default issuerReducer;
