import React, { useEffect, useState } from "react";
import "../../App.css";
import { Autocomplete, TextField } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import config from "../../config";
import Cookies from "js-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import LeftPanel from "../LeftPanel/LeftPanel";
import FooterText from "../Footer/Footer";
import { useTranslation } from 'react-i18next';

const shown = {
    visibility: "visible",
    opacity: "1",
    transition: "0.01s",
};

const hidden = {
    visibility: "hidden",
    opacity: "0",
    transition: "visibility 0s 2s, opacity 2s linear",
};

export default function Verifier(props) {
    const { t } = useTranslation();
    const [multipleTypes, setMultipleTypes] = useState(true);
    const navigate = useNavigate();
    const [verifierTypes, setVerifierTypes] = useState([]);
    const [searchParams] = useSearchParams();

    let cookie = Cookies.get(config.cookieName);
    if (!cookie) {
        if (props.action === "issuanceNotification") {
            window.location.href =
                "/sign-in?redirect_uri=" +
                window.location.origin +
                "/initiate_issuance?" +
                encodeURIComponent(searchParams.toString());
        } else if (props.action === "credentialNotification") {
            window.location.href =
                "/sign-in?redirect_uri=" +
                window.location.origin +
                "/credential?" +
                encodeURIComponent(searchParams.toString());
        } else if (props.action === "shareCredentialNotification") {
            window.location.href =
                "/sign-in?redirect_uri=" +
                window.location.origin +
                "/saveCredential?" +
                encodeURIComponent(searchParams.toString());
        } else if (props.action === "credentialOfferNotification") {
            //console.log("/sign-in?redirect_uri=" + window.location.origin + "/credential_offer?" + encodeURIComponent(searchParams.toString()));
            window.location.href =
                "/sign-in?redirect_uri=" +
                window.location.origin +
                "/credential_offer?" +
                encodeURIComponent(searchParams.toString());
        } else {
            window.location.href = "/sign-in";
        }
    }

    useEffect(() => {
        fetch(config.getAuthorizedVerifierCred)
            .then(async (response) => {
                // check for error response
                if (!response.ok) {
                    const error = await response.text();
                    return Promise.reject(error);
                } else {
                    let data = await response.json();
                    //console.log(data);
                    if (data.length > 0) {
                        setVerifierTypes(data);
                    }
                }
            })
            .catch((error) => {
                console.error("Error in API Issue: ", error);
            });
    }, []);

    /*
    useEffect(() => {
      if (verifierTypes.length == 1) {
        setMultipleTypes(false);
        //setScenario(verifierTypes[0]);
      }
    }, [verifierTypes]);*/

    const [qrCode, setQrCode] = useState("");
    const [buttonLink, setButtonLink] = useState("");
    const [show, setShow] = useState(false);
    const [scenario, setScenario] = useState("");
    const [output, setOutput] = useState();
    const [resultVerifier, setResultVerifier] = useState({});
    const [sessionId, setSessionId] = useState("");

    const handleChange = (event, value) => {
        if (value !== null) {
            setScenario(value);
        } else {
            setScenario("");
            setQrCode("");
        }
    };

    useEffect(() => {
        if (scenario !== "") {
            fetch(config.authRequestApi + "?type=" + scenario)
                .then((response) => response.json())
                .then((data) => {
                    //console.log(data);
                    setQrCode(data.url);
                    console.log(data.url);
                    setButtonLink(`${data.url}&session_id=${data.sessionId}`);
                    //setButtonLink(`${data.sameDevice}&session_id=${data.sessionId}`);

                    // SSE: sessionId is saved to sessionId variable here
                    setSessionId(data.sessionId);

                    let eventsource = new EventSource(
                        config.serverUrl + `/events?id=${data.sessionId}`
                    );

                    eventsource.onmessage = function (event) {
                        // make sure it's not a ping message!
                        if (event.data !== "ping") {
                            console.log("event data: ", event.data);
                            let url = new URL(event.data);
                            eventsource.close();
                            navigate(
                                `${url.pathname}?responseCode=${url.searchParams.get(
                                    "responseCode"
                                )}`
                            );
                        } else {
                            console.log("PING!");
                        }
                    };

                    eventsource.onerror = function (event) {
                        eventsource.close();
                    };
                });
        }
    }, [scenario]);

    /*   useEffect(() => {
      // Set SSE channel here with sessionId
  
    }, [sessionId]); */

    const startTest = () => {
        window.open(buttonLink, "_blank");
    };

    return (
        <div className="logged-out page-signin">
            <ToastContainer />
            <section className="content-wrap light-bg">
                <LeftPanel heading={t("VerifierFunc.left-page")} />
                <div className="col-content w-60">
                    <div className="container">
                        <div className="messages m-hide">
                            <h2>{t("VerifierFunc.title")} </h2>
                        </div>

                        {Object.keys(resultVerifier).length == 0 ? (
                            <>
                                {multipleTypes ? (
                                    <div className="dropdown search-container search ">
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            className="autocomplete-dropdown"
                                            options={verifierTypes}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t("VerifierFunc.form-label-1")}
                                                />
                                            )}
                                            onChange={handleChange}
                                            fullWidth={true}
                                            style={{marginTop:"25px"}}
                                        />
                                    </div>
                                ) : (
                                    <></>
                                )}

                                {scenario !== "" ? (
                                    <>
                                        <QRCodeSVG
                                            value={qrCode}
                                            height="200"
                                            width="200"
                                            className="qrcode_verify"
                                        />
                                        {/* <p className="selectTestType"> OR </p>
                                        <button
                                        id={"start-" + scenario}
                                        className="btn-primary"
                                        onClick={() => {
                                            startTest();
                                        }}
                                        >
                                        Verify with {scenario}
                                        </button> */}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
                <FooterText style="m-show" />
            </section>
        </div>
    );
}
