import { RETRIEVE_EBSI_SCHEMAS } from "../actions/types";

const initialState = [];

function schemaReducer(schemas = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_EBSI_SCHEMAS:
      return payload;

    default:
      return schemas;
  }
}

export default schemaReducer;
