import React, { useState, useEffect, useCallback } from "react";
import {
  useSearchParams,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import LeftPanel from "../LeftPanel/LeftPanel";
import { Autocomplete, TextField } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import Cookies from "js-cookie";

import FooterText from "../Footer/Footer";
import config from "../../config";

import tick from "../../img/tick.png";
import alert from "../../img/alert_2.png";
import cross from "../../img/cross.png";
import StepNavigation from "./StepNavigation";

const InspectorResult = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useSearchParams();

  const [credentialSubject, setCredentialSubject] = useState();
  const [credentialType, setCredentialType] = useState();
  const [showSubject, setShowSubject] = useState();
  const [feedback, setFeedback] = useState("");
  const [step, setStep] = useState(0);
  const [credSubError, setCredSubError] = useState({});
  const [verifierdata, setVerifierData] = useState();

  let cookie = Cookies.get(config.cookieName);
  if (!cookie) {
    window.location.href = "/sign-in";
  }

  useEffect(() => {
    if (!credentialSubject) {
      let code = queryParams.get("responseCode");
      let type = queryParams.get("type");
      if (code !== null) {
        setCredentialType(type);
        const url =
          config.serverUrl + config.responseResultApi + "?responseCode=" + code;
        // console.log(url);
        // HTTP GET to get the credentialSubject
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            if (data.status === false) {
                setCredSubError(data);

                setVerifierData({
                    "vp":{
                        "title":data.error_code === 1 ? "Credential presentation has expired" : "Verifiable presentation is valid",
                        "status": !(data.error_code < 2)
                    },
                    "type":{
                        "title": "Document is "+ type === "eIDCredential" ? "an eID" : type === "TravelAuthorizationCredential"
                        ? "Document is a Travel Authorization Credential" : "Document is an " + type,
                        "status": correctDoc(type)
                    },
                    "vc":{
                        "title": data.error_code === 2 ? "Verifiable credential has expired" : "Verifiable credential is valid",
                        "status": !(data.error_code < 3)
                    },"revoked":{
                        "title": data.error_code === 3 ? "Verifiable credential is revoked" : "Verifiable credential is not revoked",
                        "status": !(data.error_code < 4)
                    }       
                })
            }
            else{
                setVerifierData({
                    "vp":{
                        "title":"Verifiable presentation is valid",
                        "status":true
                    },
                    "type":{
                        "title":"Document is "+ type === "eIDCredential" ? "an eID" : type === "TravelAuthorizationCredential"
                        ? "Document is a Travel Authorization Credential" : "Document is an " + type,
                        "status":correctDoc(type)
                    },
                    "vc":{
                        "title": "Verifiable credential is valid",
                        "status": true
                    },
                    "revoked":{
                        "title": "Verifiable credential is not revoked",
                        "status": true
                    }      
    
                })
            }
            setCredentialSubject(data.credential_subject);
            
          })
          .catch((reason) => {
            console.log("error getting credential subject", reason);
          });
      } else {
        navigate("/inspectorApp");
      }
    }
    setQueryParams("");
  }, []);

  function correctDoc(type) {
    if(!type) {
        type = credentialType
    }
    return (
      type === "eIDCredential" ||
      type === "TravelAuthorizationCredential"
    );
  }

  function formatKey(key) {
    switch (key) {
      case "eid":
        return "eID";
      case "end_date":
        return "Valid until";
      case "start_date":
        return "Valid from";
      default:
        return key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " ");
    }
  }

  const sendFeedback = () => {
    // Call api for feedback     feedback
    let url = "/api/v1/verification_feedback";
    console.log(verifierdata);
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        feedback: feedback,
        vc_type: credentialType,
        error_code: credSubError.error_code ?? 0,
        error_reason: credSubError.error_reason ?? "",
        extra_information:{
            credential_subject: credentialSubject,
            verifier_data: verifierdata, 
        }
       
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        //console.log(data);
        setStep(3);
      })
      .catch((error) => {
        toast.error(t("Details.toast-error"));
        console.log(error);
        setStep(3);
      });
    }

    function isISO8601(value) {
      // Regular expression to check the basic structure of the date-time string
      const regex =
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(Z|[+-]\d{2}:\d{2})?$/;

      if (!regex.test(value)) {
        // If it doesn't match the basic structure, it's not a valid ISO 8601 string
        return false;
      }

      // Try to parse the date using the Date constructor
      const date = new Date(value);

      // Check if the date is valid
      return !isNaN(date.getTime());
    }

    const formatValues = (value) => {
      if (isISO8601(value)) {
        return formateIso8601Date(value);
      } else {
        return value;
      }
    };

    function formateIso8601Date(isoString) {
      const date = new Date(isoString);

      // Ensure the date is valid
      if (!isNaN(date.getTime())) {
        // Get the year, month, and day parts
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
        const day = date.getDate().toString().padStart(2, "0");

        // Format to YYYY-MM-DD
        return `${year}-${month}-${day}`;
      } else {
        // Handle invalid date
        console.error("Invalid date");
        return null;
      }
    }

    return (
      <div className="logged-out page-signin">
        <ToastContainer />

        <section className="content-wrap light-bg">
          <LeftPanel heading={t("InspectorApp.left-page")} isInspector={true}/>

          <div className="col-content w-60">
            <div className="container">
              <StepNavigation
                step={step === 0 ? 2 : step === 1 ? 3 : step === 2 ? 3 : 4}
              />
              <br />
              {/*<div className="messages m-hide">
                            <h2>{t("InspectorApp.title")} </h2>
                        </div>*/}

              <div>
                {step === 0 ? (
                  <>
                    <h3>Verification results:</h3>
                    <table className="table-inspectorApp">
                      {console.log(credentialType)}
                      {Object.keys(credSubError).length != 0 ? (
                        <tbody>
                          <tr>
                            <td>
                              <img
                                style={{ width: "20px", height: "20px" }}
                                className="table-img"
                                src={credSubError.error_code == 1 ? alert : tick}
                              />
                            </td>
                            <td>
                              {credSubError.error_code == 1
                                ? "Credential presentation has expired"
                                : "Verifiable presentation is valid"}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                style={{ width: "20px", height: "20px" }}
                                className="table-img"
                                src={correctDoc() ? tick : alert}
                              />
                            </td>
                            <td>
                              Document is{" "}
                              {credentialType === "eIDCredential"
                                ? "an eID"
                                : credentialType ===
                                  "TravelAuthorizationCredential"
                                ? "a Travel Authorization Credential"
                                : "an unknown"}
                            </td>
                          </tr>
                          <tr className={credSubError.error_code < 2 ? "grayOut" : ""}>
                            <td>
                              <img
                                style={{ width: "20px", height: "20px" }}
                                className="table-img"
                                src={credSubError.error_code < 3 ? alert : tick}
                              />
                            </td>
                            <td>
                              {credSubError.error_code == 2
                                ? "Verifiable credential has expired"
                                : "Verifiable credential is valid"}
                            </td>
                          </tr>
                          <tr className={credSubError.error_code < 3 ? "grayOut" : ""}>
                            <td>
                              <img
                                style={{ width: "20px", height: "20px" }}
                                className="table-img"
                                src={credSubError.error_code < 4 ? alert : tick}
                              />
                            </td>
                            <td>
                              {credSubError.error_code == 3
                                ? "Verifiable credential is revoked"
                                : "Verifiable credential is not revoked"}
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td>
                              <img
                                style={{ width: "20px", height: "20px" }}
                                className="table-img"
                                src={tick}
                              />
                            </td>
                            <td>Verifiable presentation is valid</td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                style={{ width: "20px", height: "20px" }}
                                className="table-img"
                                src={correctDoc() ? tick : alert}
                              />
                            </td>
                            <td>
                              Document is{" "}
                              {credentialType === "eIDCredential"
                                ? "an eID"
                                : credentialType ===
                                  "TravelAuthorizationCredential"
                                ? "a Travel Authorization Credential"
                                : "an unknown"}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                style={{ width: "20px", height: "20px" }}
                                className="table-img"
                                src={tick}
                              />
                            </td>
                            <td>Verifiable credential is valid</td>
                          </tr>
                          <tr>
                            <td>
                              <img
                                style={{ width: "20px", height: "20px" }}
                                className="table-img"
                                src={tick}
                              />
                            </td>
                            <td>Verifiable credential is not revoked</td>
                          </tr>
                        </tbody>
                      )}
                    </table>

                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        if (
                          !credentialSubject &&
                          Object.keys(credSubError).length == 0
                        ) {
                          navigate("/inspectorApp");
                        } else if (
                          !credentialSubject &&
                          Object.keys(credSubError).length != 0
                        ) {
                          setStep(2);
                        } else {
                          setStep(1);
                        }
                      }}
                    >
                      {!credentialSubject &&
                      Object.keys(credSubError).length == 0
                        ? "Try again"
                        : "Next"}
                    </button>
                  </>
                ) : step === 1 ? (
                  <>
                    <h3>Document details:</h3>
                    <table className="table">
                      <tbody>
                        {Object.keys(credentialSubject).map((key, index) => {
                          if (typeof credentialSubject[key] === "object") {
                            return Object.keys(credentialSubject[key]).map(
                              (key2) => {
                                return (
                                  <tr key={key2}>
                                    <td
                                      className="col-1"
                                      style={{ paddingRight: "15px" }}
                                    >
                                      {formatKey(key2)}
                                    </td>
                                    <td className="col-2">
                                      {credentialSubject[key][key2]
                                        ? formatValues(
                                            credentialSubject[key][key2]
                                          )
                                        : "/"}
                                    </td>
                                  </tr>
                                );
                              }
                            );
                          } else {
                            return (
                              <tr key={key}>
                                <td
                                  className="col-1"
                                  style={{ paddingRight: "15px" }}
                                >
                                  {formatKey(key)}
                                </td>
                                <td className="col-2">
                                  {credentialSubject[key]
                                    ? formatValues(credentialSubject[key])
                                    : "/"}
                                </td>
                              </tr>
                            );
                          }
                        })}
                      </tbody>
                    </table>
                    <button
                      className="btn btn-primary"
                      onClick={() => setStep(2)}
                    >
                      Next
                    </button>
                  </>
                ) : step === 2 ? (
                  <>
                    <h3>Verification notes:</h3>
                    <textarea
                      style={{ width: "100%", height: "100px" }}
                      value={feedback}
                      placeholder="Add comments about verification and submit the report to your verification system."
                      onChange={(e) => setFeedback(e.target.value)}
                    ></textarea>
                    <br />
                    <button
                      className="btn btn-primary"
                      onClick={() => sendFeedback()}
                    >
                      Next
                    </button>
                  </>
                ) : step === 3 ? (
                  <>
                    <table className="table-inspectorApp">
                      <tbody>
                        <tr>
                          <td>
                            <img
                              style={{ width: "20px", height: "20px" }}
                              className="table-img"
                              src={tick}
                            />
                          </td>
                          <td>
                            Your verification report was successfully submitted.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button
                      className="btn btn-primary"
                      onClick={() => navigate("/inspectorApp")}
                    >
                      Start new verification
                    </button>
                  </>
                ) : (
                  <>Unknown step</>
                )}
              </div>
            </div>
          </div>

          <FooterText style="m-show" />
        </section>
      </div>
    );
};

export default InspectorResult;
