import React from "react";
import config from "../../config";
import GoogleAnalytics from "../GA";
import { useTranslation } from 'react-i18next';

function AboutPortfolio(props) {

    const { t } = useTranslation();
    let GA_portfolio = () => {
        // GA4 Btn for portfolio
        GoogleAnalytics('portfolio_onboarding_banner', 'User');
    }

    return (
        props.onboarded != null &&
        !props.onboarded && (
            <div className={`aboutPortfolio ${props.style}`}>
                <h3>{t("AboutPortfolio.title")} </h3>

                <p>
                    {t("AboutPortfolio.about")}{" "}
                </p>

                <a
                    href={config.loginPortfolio}
                    target="_blank"
                    onClick={() => { GA_portfolio() }}
                >
                    {t("AboutPortfolio.link")}
                </a>
            </div>
        )
    );
}

export default AboutPortfolio;
