import * as React from "react";
import LeftPanelNotifications from "../LeftPanel/LeftPanelNotifications";

import config from "../../config";
import useNotifications from "../Navigation/notification";

import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import FooterText from "../Footer/Footer";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GoogleAnalytics from "../GA";

// Creating new theme - mui to change 1 color setting -.-
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from 'react-i18next';

const { palette } = createTheme();
const theme = createTheme({
    palette: {
        secondary: {
            main: "#934beb",
        },
        primary: {
            main: "#231f20",
        },
    },
    typography: {
        fontFamily: "Saira",
    },
});

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}));

function DeferredNotification() {
    const { t } = useTranslation();
    const [value, setValue] = React.useState("1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tabPanelStyle = {
        padding: "0px",
    };

    const notifications = useNotifications(config.deferredNotificationUrl);

    let GA_addCredential = (path) => {
        // GA4 Btn for new credential
        GoogleAnalytics('new_credential_' + path + '_btn', 'User');
    }

    // History
    const [history, setHistory] = React.useState([]);

    React.useEffect(() => {
        getNotificationsHistory();
    }, []);

    const getNotificationsHistory = () => {
        fetch(config.getDeferredNotification + "?active=false")
            .then(async (response) => {
                let text = await response.text();
                if (!response.ok) {
                    throw new Error(text);
                } else {
                    const data = JSON.parse(text);
                    //console.log(data);

                    if (data !== null) {
                        setHistory(data);
                    }
                }
            })
            .catch((e) => {
                //throws error when user is not loged in / session expired
                console.log(e);
            });
    }

    return (
        <>
            <div className="logged-out page-signin">
                <ToastContainer />
                <section className="content-wrap light-bg">

                    <LeftPanelNotifications heading={t("DeferredNotification.left-page")} />

                    <div className="col-content w-60">
                        <div className="notifications-container">
                            <div className="messages m-hide">
                                <p className="light">{t("DeferredNotification.title-light")} </p>
                                <h2>{t("DeferredNotification.title")}</h2>
                            </div>

                            <ThemeProvider theme={theme}>
                                <Box sx={{ width: "100%", typography: "body1" }}>
                                    <TabContext value={value}>
                                        <Box>
                                            <TabList
                                                value={value}
                                                onChange={handleChange}
                                                sx={{
                                                    "& .css-9zg40m-MuiButtonBase-root-MuiTab-root": {
                                                        //padding: "0 20px 0 0 !important",
                                                        padding: 0,
                                                        marginRight: "20px",
                                                    },
                                                    "& .MuiTab-root.Mui-selected": {
                                                        color: "#231f20",
                                                    },
                                                    "& .MuiTab-textColorSecondary": {
                                                        color: "#CECECE",
                                                    },
                                                }}
                                                textColor="secondary"
                                                indicatorColor="secondary"
                                                aria-label="secondary tabs example"
                                            >
                                                <Tab
                                                    label={t("DeferredNotification.tab-1") +" / " +notifications.length}
                                                    value="1"
                                                />
                                                <Tab label={t("DeferredNotification.tab-2") +" / "  + history.length} value="2" />
                                            </TabList>
                                        </Box>

                                        <TabPanel sx={tabPanelStyle} value="1">
                                            <Notifi getNotificationsHistory={getNotificationsHistory} />
                                        </TabPanel>
                                        <TabPanel sx={tabPanelStyle} value="2">
                                            <NotifiHistory history={history} />
                                        </TabPanel>
                                    </TabContext>
                                </Box>
                            </ThemeProvider>
                            <Link to="/explorer">
                                <div className="add m-hide">
                                    <img src="html/img/plus.svg" alt="Add new" onClick={() => { GA_addCredential("plus") }} />
                                </div>
                            </Link>
                        </div>
                    </div>
                    <FooterText style="m-show" />
                </section>
            </div>
        </>
    );
}

export default DeferredNotification;

//------------------------------------------------------------------------------//

const MyCardHeader = styled(CardHeader)({
    display: "flex",
    justifyContent: "space-between",
    //alignItems: 'center',
    flexWrap: "wrap",
    "& .MuiCardHeader-content": {
        display: "flex",
    },
    "& .MuiCardHeader-title": {
        flexGrow: 1,
        textAlign: "left",
        fontSize: "17px",
        fontWeight: "500",
        span: {
            width: "50%",
        },
    },
    "& .MuiCardHeader-subheader": {
        textAlign: "right",
        paddingRight: "10px",
        whiteSpace: "nowrap",
    },
    margin: "0",
    padding: "8px 8px 4px",
});


function notificationTranslateTitle(t, details) {
    let translationId = "Notification." + details.notification_type + ".title";
    let out = t(translationId);
    if (out === translationId) {
        return details.details;
    }
    while(out.search(/{{[a-zA-Z0-9\.]+}}/g) > -1) {
        out = out.replace(/{{[a-zA-Z0-9\.]+}}/g, (match) => {
            return details[match.substring(2, match.length - 2)]
        })
    }
    return out
}

function notificationTranslateDetails(t, details) {
    let translationId = "Notification." + details.notification_type + ".description";
    let out = t(translationId);
    if (out === translationId) {
        return details.details;
    }
    while(out.search(/{{[a-zA-Z0-9\.]+}}/g) > -1) {
        out = out.replace(/{{[a-zA-Z0-9\.]+}}/g, (match) => {
            return details[match.substring(2, match.length - 2)]
        })
    }
    return out
}

export function Notifi(props) {
    const notifications = useNotifications(config.deferredNotificationUrl);
    const { t } = useTranslation();

    const [expanded, setExpanded] = React.useState(
        notifications.map(() => false)
    );

    React.useEffect(() => {
        //console.log("Changed notifications");
    }, [notifications]);

    const handleExpandClick = (index) =>
        function () {
            const newExpanded = expanded.map((el, i) => {
                if (el && i == index) {
                    return el;
                }
                return false;
            });

            newExpanded[index] = !newExpanded[index];
            setExpanded(newExpanded);
        };

    let onClick = (e) => {
        //Get id
        let [actionBtn, id] = e.target.id.split("_");

        // Send info to middleware
        fetch(config.updateNotification + "?id=" + id + "&action=" + actionBtn)
            .then(async (response) => {
                let text = await response.text();

                if (!response.ok) {
                    throw new Error(text);
                } else {
                    toast.info(t("DeferredNotification.toast-save-success"));
                    props.getNotificationsHistory();
                }
            })
            .catch((e) => {
                toast.error(t("DeferredNotification.toast-save-error")+" \n" + e);
            });
    };

    function markAsReadHandler(event) {
        let [actionBtn, id] = event.target.id.split("_");
        // Send info to middleware
        fetch(config.updateNotification + "?id=" + id)
            .then(async (response) => {
                let text = await response.text();

                if (!response.ok) {
                    throw new Error(text);
                } else {
                    toast.info(t("DeferredNotification.toast-save-success"));
                    props.getNotificationsHistory();
                }
            })
            .catch((e) => {
                toast.error(t("DeferredNotification.toast-save-error")+" \n" + e);
            });
    }

    const renderData = () => {
        if (notifications.length !== expanded.length) {
            setExpanded(notifications.map(() => false));
        }

        if (notifications.length !== 0) {
            return notifications.map((el, index) => {
                return (
                    <div className="cardNotify clickable" key={el.id}>
                        <Card
                            onClick={handleExpandClick(index)}
                            sx={{
                                borderRadius: "0px",
                                boxShadow: "none",
                                marginBottom: "2px",
                                "& .MuiCardHeader-root": {
                                    backgroundColor: "rgba(0,0,0, 0.06)",
                                    fontSize: "17px",
                                },
                                "& .MuiCollapse-root": {
                                    backgroundColor: "rgba(0,0,0, 0.03)",
                                    borderTop: "2px solid white",
                                },
                                "& .MuiCardContent-root": {
                                    padding: "0px",
                                },
                                "& .MuiCardContent-root:last-child": {
                                    padding: "0px",
                                },
                            }}
                        >
                            <MyCardHeader
                                /*action={
                                    <ExpandMore
                                        expand={expanded[index]}
                                        /* onClick={handleExpandClick(index)} */
                                        /*
                                        aria-expanded={expanded[index]}
                                        aria-label="show more"
                                    >
                                        <ExpandMoreIcon />
                                    </ExpandMore>
                                }*/
                                title={notificationTranslateTitle(t, el.data)}
                                subheader={el.data.date}
                                className="notification-header"
                            />

                            <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <div className="notifications-details-container">
                                        <p className="notifications-details-text text-break">{notificationTranslateDetails(t, el.data)}</p>
                                        {el.data.action === "true" ? (
                                            <CardActions>
                                                <Button
                                                    size="small"
                                                    id={"accept_" + el.id}
                                                    onClick={onClick}
                                                    sx={{ backgroundColor: "white" }}
                                                >
                                                   {t("DeferredNotification.notify-accept-btn")}
                                                </Button>
                                                <Button
                                                    size="small"
                                                    id={"decline_" + el.id}
                                                    onClick={onClick}
                                                    sx={{ backgroundColor: "white" }}
                                                >
                                                    {t("DeferredNotification.notify-decline-btn")}
                                                </Button>
                                            </CardActions>
                                        ) : (
                                            <CardActions>
                                                <Button
                                                    size="small"
                                                    id={"accept_" + el.id}
                                                    onClick={markAsReadHandler}
                                                    sx={{ backgroundColor: "white" }}
                                                >
                                                    {t("DeferredNotification.notify-read-btn")}
                                                </Button>
                                            </CardActions>
                                        )}
                                    </div>
                                </CardContent>
                            </Collapse>
                        </Card>
                    </div>
                );
            });
        } else {
            return (
                <div className="messages">
                    <p> {t("DeferredNotification.notify-no-msg")}</p>
                </div>
            );
        }
    };

    return (
        <>
            <div>{renderData()}</div>
        </>
    );
}

//------------------------------------------------------------------------------//
export function NotifiHistory(props) {
    let history = props.history
    const { t } = useTranslation();

    const [expanded, setExpanded] = React.useState(history.map(() => false));

    const handleExpandClick = (index) =>
        function () {
            const newExpanded = expanded.map((el, i) => {
                if (el && i == index) {
                    return el;
                }
                return false;
            });

            newExpanded[index] = !expanded[index];
            setExpanded(newExpanded);
        };

    const renderData = () => {
        if (history.length !== expanded.length) {
            setExpanded(history.map(() => false));
        }

        if (history.length !== 0) {
            return history.map((el, index) => {
                return (
                    <div className="cardNotify clickable" key={el.id}>
                        <Card
                            onClick={handleExpandClick(index)}
                            sx={{
                                borderRadius: "0px",
                                boxShadow: "none",
                                marginBottom: "2px",
                                "& .MuiCardHeader-root": {
                                    backgroundColor: "rgba(0,0,0, 0.06)",
                                    fontSize: "17px",
                                },
                                "& .MuiCollapse-root": {
                                    backgroundColor: "rgba(0,0,0, 0.03)",
                                    borderTop: "2px solid white",
                                },
                                "& .MuiCardContent-root": {
                                    padding: "4px",
                                },
                                "& .MuiCardContent-root:last-child": {
                                    padding: "0px",
                                },
                            }}
                        >
                            <MyCardHeader
                                /*action={
                                    <ExpandMore
                                        expand={expanded[index]}
                                        aria-expanded={expanded[index]}
                                        aria-label="show more"
                                    >
                                        <ExpandMoreIcon />
                                    </ExpandMore>
                                }*/
                                title={notificationTranslateTitle(t, el.data)}
                                subheader={el.data.date}
                            />

                            <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <div className="notifications-details-container">
                                        <p className="notifications-details-text  text-break">{notificationTranslateDetails(t, el.data)}</p>
                                        {/* {el.data.action && (
                                            <p className="notifications-details-action">
                                                Action: {el.data.action}
                                            </p>
                                        )} */}
                                    </div>
                                </CardContent>
                            </Collapse>
                        </Card>
                    </div>
                );
            });
        } else {
            return (
                <div className="messages">
                    <p>{t("DeferredNotification.histry-no-msg")}</p>
                </div>
            );
        }
    };

    return (
        <>
            <div>{renderData()}</div>
        </>
    );
}
