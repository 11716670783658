import React from 'react'

import stepActive from '../../img/step_active.png';
import stepNotActive from '../../img/step-notactive.png';
import stepTick from '../../img/step_tick.png';

const StepNavigation = ({step = 0}) => {

    const steps = [
        "SELECT DOCUMENT",
        "REQUEST VERIFICATION",
        "CHECK RESULTS",
        "FINISH VERIFICATION"
    ]

    //completed
    return (
        <div className="stepper-wrapper">
            {steps.map((name, index) => <div key={name} className={'stepper-item ' + (index < step ? 'completed' : index == step ? 'active': '')}>
                <div className="step-counter"><img style={(index < step ? {width: '15px', height: '15px'} : {})} src={(index < step ? stepTick : index == step ? stepActive: stepNotActive)}/></div>
                <div className="step-name">{name}</div>
            </div>)}
        </div>
    )
}

export default StepNavigation
