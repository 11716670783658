import http from "../walletApis";

class CredentialsDataService {
  getAll() {
    return http.get("/list_credentials");
  }

  get(data) {
    return http.post("/get_credential", data);
  }
  // TODO
  delete(id) {
    return http.delete(`/delete_credential`);
  }
}

export default new CredentialsDataService();
