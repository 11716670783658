function config() {
    let serverUrl = window.location.origin;
    let issuer = 'https://test-issuer.mynext.id';
    let portfolio = 'https://test-portfolio.mynext.id';
    let GA_tracking_id = 'G-8WTN46ZN3E'; // Default is test / dev
   
    if(serverUrl === 'http://localhost:8001'){
        console.log("Switching to localhost settings");
        issuer = 'http://localhost:8000';
        portfolio = 'http://localhost:8002';
    }

    if(serverUrl === 'https://wallet.mynext.id'){
        GA_tracking_id = 'G-R4GCNZEJ53'; // For production
        issuer = 'https://issuer.mynext.id';
        portfolio = 'https://portfolio.mynext.id';
    }


    return {
        serverUrl: serverUrl,
        // Issuers list and VC list API endpoints
        issuerListUrl: issuer + "/api/v1",
        // Issuer Metadata endpoint
        issuerMetadataEndpoint: issuer + "/.well-known/openid-configuration",
        // Issuer URL
        issuerURL: issuer,
        // Cookie name
        cookieName: "wallet_session",
        // Auth0 Login URL
        auth0Login: serverUrl + "/auth/login",
        // Auth0 Logout URL
        auth0Logout: serverUrl + "/auth/logout",
        // Callback URL
        callbackUrl: serverUrl + "/api/v1/cb",
        // Save credential URL
        saveCredentialUrl: serverUrl + "/api/v1/save_credential",
        // Get credential URL
        getCredentialUrl: serverUrl + "/api/v1/get_credential",
        // Verify EUROPASS credential URL
        verifyCredentialUrl: serverUrl + "/api/v1/verify_credential",
        // Deposit credential to Europass
        depositToEuropass: serverUrl + "/api/v1/deposit_to_europass",
        // Delete credential URL
        deleteCredentialUrl: serverUrl + "/api/v1/delete_credential",
        // Connect to notification deferred channel
        deferredNotificationUrl: serverUrl + "/api/v1/deferredNotification",
        // Get the list of notifications from vault
        getDeferredNotification: serverUrl + "/api/v1/getNotification",
        // Delete notification from vault
        updateNotification: serverUrl + "/api/v1/updateNotification",
        // Wallet API endpoints
        walletApi: serverUrl + "/api/v1",
        // Trust Framework API endpoints
        trustFrameworkApi: serverUrl + "/api/v1",
        // Login to Portfolio
        loginPortfolio: serverUrl + "/api/v1/portfolio_login",
        // Wallet public endpoint
        publicEndpoint: serverUrl + "/api/v1/public",
        // Portfolio landing
        portfolioLanding: portfolio,
        // Portfolio onboarding check
        portfolioOnboarding : serverUrl + '/api/v1/portfolio_onboard_check',
        // Wallet login email retrieve
        walletUser: serverUrl + "/api/v1/user",

        // Wallet relevant credentials for verifier
        getCredentialVerifier: serverUrl + "/api/v1/verifierRequest",
        getSelectedShareVerifier: serverUrl + "/api/v1/verifierResponse",

        // GA tracking id
        ga_id: GA_tracking_id,

        // Set types of credentials that can be requested
        getAuthorizedVerifierCred : "/api/v1/verifier/list_types",
        authRequestApi: "/api/v1/verifier/authorize",
        responseResultApi: "/api/v1/verifier/response_result",

        eidDemo:"http://localhost:3000",
    };
}

export default config();
