import LeftPanel from "../LeftPanel/LeftPanel";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
    const { t } = useTranslation();

    return (
        <div className="logged-out page-signin">
            <section className="content-wrap light-bg">

                <LeftPanel heading={t("Privacy.left-page")} />

                <div className="col-content about w-60">
                    <h3>{t("Privacy.title")}</h3>
                </div>
            </section>
        </div>
    );
};

export default PrivacyPolicy;
