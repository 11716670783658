import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";

function SearchAutocomplete(props) {
  const data = props.data.map((item) => {
    return item.name;
  });

  function handleChange(event, value) {
    props.onSelected(data.indexOf(value));
    setPreselectedValue(value);
  }
  const [preselectedValue, setPreselectedValue] = useState(props.preselected ? props.preselected: null);

  useEffect(() => {
    if (props.preselected) {
      setPreselectedValue(props.preselected);
    }else{
      setPreselectedValue(null);
    }
  }, [props.preselected]);



  return (
    <div className="search">
      <Autocomplete
        value={preselectedValue}
        defaultValue={preselectedValue}
        disablePortal
        id="combo-box-demo"
        key={props.resetKey}
        options={data}
        renderInput={(params) => <TextField {...params} label={props.label} />}
        onChange={handleChange}
        fullWidth={true}
        sx={{
          width: "96%",
          margin: "10px 5px",
          "& .MuiFormLabel-root": {
            fontFamily: "Saira",
            color: "#9B9B9B",
          },
          "& .MuiInputBase-root": {
            fontFamily: "Saira",
            borderColor: "#9B9B9B",
          },
        }}
      />
    </div>
  );
}
export default SearchAutocomplete;
