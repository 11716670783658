import React from "react";

export default function Popup(props) {
    function onAction(event) {
        props.onAction(event.target.value);
    }

    return (
        <React.Fragment>
            <div className="logged-out page-signin">
                <div className="popup-overlay">
                    <div className="popup">
                        <p>{props.heading}</p>
                        <h3>{props.description}</h3>
                        <div className="popup-actions">
                            <button
                                className="btn btn-primary"
                                value="true"
                                onClick={onAction}
                            >
                                {props.acceptText}
                            </button>
                            <br /> <br />
                            <button
                                className="btn btn-secondary"
                                value="false"
                                onClick={onAction}
                            >
                                {props.declineText}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
