import React, { createElement } from "react";
import config from "../../config";
import CredentialCard from "./credentialCard";

function SimpleProofCredentials() {
  const credsData = [
    {
      id: "1",
      name: "Email",
      credential: "Credential",
      image: "html/img/card_email_purple.svg",
      link: '/initiate_issuance?credential_offer={"credential_issuer":"'+config.issuerURL+'", "scope":"id.mynext.emailWalletCredential", "credential_name": "MyNextID Email Credential","issuer_name":"MyNextID Issuer"}'
    },
    {
      id: "2",
      name: "Mobile",
      credential: "Credential",
      image: "html/img/card_mobile_turquize.svg",  
      inactive: "html/img/card_mobile_grey.svg"    
    },
    {
      id: "3",
      name: "Twitter",
      credential: "Credential",
      image: "html/img/card_tw_blue.svg",
      inactive: "html/img/card_tw_grey.svg"
    }
  ];

  function issueCredentialHandler(name, index) {
    if(credsData[index-1].link != null){
      window.location.href = credsData[index-1].link;
    }
  }


  

  return (
    <div className="explorer m-hide" > 
      {credsData.map((credential) => ( 

        <CredentialCard key={credential.id} id={credential.id} name={credential.name} credential="Credential" image={credential.image} onSelect={issueCredentialHandler}/>
      ))}
    </div>
  );
}

export default SimpleProofCredentials;
